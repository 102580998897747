import {template} from 'core-utils';
import {STCK_STORE, ACTIONS, SELECTORS} from '@oneaudi/stck-store';
import {scModelFinanceForm as FF_MODEL} from '../crs-finance/sc-model-finance-form';
import {leasingElementTemplate} from './leasing-element-template';
import {RequestController, svdModel} from '../../stockcars-bundle';
import {OBJECT_UTILS, FORMAT_UTILS} from '../../stockcars-utils-bundle';
import {crsApi as CRS_API} from '../crs-finance/crs-api';

const LEASING_FOOTNOTE = `
	<a href='#fn_leasing' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
`;

export default class LeasingElement extends HTMLElement {
	constructor() {
		super();
		this.unsubscribeFinanaceStateObserver = null;
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		this._template = this.dataset.template || '';
		this._vehicleId = this.dataset.vehicleId || '';
		this.update = this.update.bind(this);
		const data = await this._initializeProperties(template);
		this._render(data);
		this.unsubscribeFinanaceStateObserver = STCK_STORE.observeStateFromStore(SELECTORS.FINANCING.getCalculateDataForVehicleId, this.update);
	}

	/**
	 * disconnected callback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this.unsubscribeFinanaceStateObserver();
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{selectorLayerWrapper: string}>} defaults
	 */
	static get defaults() {
		return {
			selectorRate: '.sc-leasing-element-value-price',
			selectorDisclaimer: '.sc-leasing-element-disclaimer'
		};
	}

	_hasDealerFinanceFlag(vehicle) {
		const dealerFinancingDataAvailable = vehicle?.dealerFinancingData?.valid === true;
		const isEcomVehicle = vehicle?.buyableOnline === true;
		return !!dealerFinancingDataAvailable && !!isEcomVehicle && !SETUPS.get('scopes.hide.finance.in.tiles') && SETUPS.get('stockcar.financing.rate.show');
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} void
	 */
	// eslint-disable-next-line max-statements
	async _initializeProperties() {
		try {
			const vehicleId = this.dataset.vehicleId || '';
			let financing, calculateRate;
			const fullVehicleData = await this._getVehicleData(vehicleId);
			const vehicleData = fullVehicleData.basic;
			let data = {};
			data.id = vehicleId;
			data.layout = this.dataset.template || '';
			data.hasDealerFinanceFlag = this._hasDealerFinanceFlag(vehicleData);
			data.template = this._template;
			if (!data.hasDealerFinanceFlag && vehicleData.financing) {
				// TODO get data form details
				const financeData_ = {};
				calculateRate = await this._storeFinancingData(fullVehicleData, financeData_);
				financing = vehicleData.financing || {};

				// Build leasing string
				let leasingDisclaimer = window.i18n['sc.details.leasing.disclaimer.text'] || '';
				// used in the template to determine if fallback needs to be used
				const hasLeasingTemplate = leasingDisclaimer.includes('%s');

				const leasingObject = (!!calculateRate && calculateRate !== '') ? this._getCalculateRateLeasingData(calculateRate) : this._getLeasingData(financing);

				// replace template in i18n value with leasing rate
				leasingDisclaimer = leasingDisclaimer.replace('*%s*', '<span class="sc-leasing-element-value-price">%s</span>').replace('%s', leasingObject.value);

				data.hasLeasingTemplate = hasLeasingTemplate;
				data.leasingRateAndDisclaimer = leasingDisclaimer;
				data.leasing = leasingObject;
				this._setVehicleRate(fullVehicleData);
			}
			return data;
		}
		catch (e) {
			console.log(e, 'Cannot parse leasing data from vehicle finance');
			return {};
		}
	}

	/**
	 * store financing data
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @param {object} financeData_ - finance data
	 * @returns {void} return nothing
	 */
	async _storeFinancingData(vehicleData_) {
		if (this._template === 'carinfo' && Object.keys(vehicleData_).length > 0) {
			return await this._getCurrentFinancingData(vehicleData_);
		}
	}

	/**
	 * get current financing data
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @param {object} financeData_ - finance data
	 * @returns {object} return calculate rate object
	 */
	async _getCurrentFinancingData(vehicleData = {}) {
		const financeData = vehicleData.detail.financingRequest.Request;
		const productFinanceData = vehicleData.detail.financingProductsRequest?.Request || financeData;

		try {
			const url = SETUPS.get('stockcar.financing.crs.serviceurl');
			const productData = await FF_MODEL.getProducts(productFinanceData);
			const defaultProductData = (productData.defaultProduct && productData.defaultProduct['@ID'] && productData.Product.length > 1) ? {'@ID': productData.defaultProduct['@ID']} : {};
			const defaultResponse = await CRS_API.getDefaultRawDataForProduct(url, financeData);
			const calculateData = await FF_MODEL.getCalculateRateDataLeasing(financeData);
			STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
				vehicleId: vehicleData.basic.id,
				financing: {defaultResponse, defaultProductData, calculateData, vehicleData: {'default': financeData, product: productFinanceData}}
			}));
			return calculateData;
		}
		catch (e) {
			console.error(e);
		}
	}

	/**
	 * set vehicle rate
	 * @private
	 * @param {object} vehicleData - vehicle data
	 * @param {object} financeData_ - finance data
	 * @returns {void} return nothing
	 */
	async _setVehicleRate(vehicleData = {}) {
		const financeData = vehicleData.detail.financingRequest.Request;
		const productFinanceData = vehicleData.detail.financingProductsRequest?.Request || financeData;
		STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({vehicleId: vehicleData.basic.id, financing: {vehicleData: {'default': financeData, product: productFinanceData}}}));
	}

	/**
	 * get vehicle data
	 * @private
	 * @param {object} vehicleId_ - vehicle id
	 * @returns {object} vehicleData json
	 */
	async _getVehicleData(vehicleId = '') {
		const svd = await svdModel.getSvdVersion();
		const response = await RequestController.fetchVehicleById(vehicleId, svd);
		return response.vehicle || {};
	}

	/**
	 * get leasing data
	 * @private
	 * @param {object} financing_ - financing data
	 * @returns {object} leasing - return leasing data
	 */
	_getLeasingData(financing_ = {}) {
		const leasing = {};
		if (OBJECT_UTILS.getNestedObject(financing_, "parameters".split(".")) && OBJECT_UTILS.getNestedObject(financing_, "product.label".split("."))) {
			if (financing_.parameters) {
				financing_.parameters.forEach((param) => {
					if (param.id === 'Rate') {
						let value = param.formattedValue ? param.formattedValue : param.value;
						leasing.value = FORMAT_UTILS.getPriceFormatted((value || ''), (param.unitSymbol || ''), (param.unit || ''));
					}
				});
			}
			leasing.disclaimer = LeasingElement._getCalculationDisclaimer(financing_);
			leasing.footnote = LEASING_FOOTNOTE;
		}
		return leasing;
	}

	/**
	 * get calculate rate leasing data
	 * @private
	 * @param {object} calculateRateData - calculated rate
	 * @returns {object} leasing - return leasing data
	 */
	_getCalculateRateLeasingData(calculateRateData = {}) {
		const leasing = {},
			calculateRate = !!calculateRateData && calculateRateData.Response && calculateRateData.Response !== '' ? calculateRateData.Response : {};
		if (Object.keys(calculateRate).length > 0) {
			leasing.disclaimer = (calculateRate.Disclaimers && calculateRate.Disclaimers.CalculationDisclaimer && calculateRate.Disclaimers.CalculationDisclaimer['#cdata-section']) ? calculateRate.Disclaimers.CalculationDisclaimer['#cdata-section'] : '';
			leasing.footnote = LEASING_FOOTNOTE;
			if (calculateRate.Payment) {
				calculateRate.Payment.forEach((param) => {
					if (param['@ID'] === 'Rate') {
						leasing.value = FORMAT_UTILS.getPriceFormatted((param.Value['#text'] || ''), (param.Units['@Symbol'] || ''), (param.Units['#text'] || ''));
					}
				});
			}
		}
		return leasing;
	}

	/**
	 * get calculation disclaimer
	 * @private
	 * @param {object} financing_ financing data
	 * @returns {string} disclaimer text
	 */
	static _getCalculationDisclaimer(financing_) {
		return financing_?.calculationDisclaimer ? financing_.calculationDisclaimer : '';
	}

	/**
	 * render
	 * @private
	 * @param {object} data_ - render data
	 * @returns {void} return nothing
	 */
	_render(data_) {
		if (Object.keys(data_).length) {
			this.innerHTML = template.render(leasingElementTemplate, data_);
		}
		else {
			this.setAttribute('data-state', 'inactive');
			setTimeout(() => { this.innerHTML = ''; }, 1000);
		}
	}

	/**
	 * set financing data
	 * @private
	 * @returns {void} return nothing
	 */
	_setFinancingData() {
		const rate = document.querySelector(LeasingElement.defaults.selectorRate);
		const disclaimer = document.querySelector(LeasingElement.defaults.selectorDisclaimer);
		const financing = SELECTORS.FINANCING.getCalculateDataForVehicleId(STCK_STORE.state, this._vehicleId);
		if (financing && financing.Response && financing.Response.Payment && financing.Response.Payment.length > 0) {
			let calculateRate = financing.Response.Payment.filter(r => r['@ID'] === 'Rate').length === 1 ? financing.Response.Payment.filter(r => r['@ID'] === 'Rate')[0] : {};
			if (!!disclaimer && !!rate) {
				disclaimer.innerHTML = (financing.Response.Disclaimers && financing.Response.Disclaimers.CalculationDisclaimer && financing.Response.Disclaimers.CalculationDisclaimer['#cdata-section']) ? financing.Response.Disclaimers.CalculationDisclaimer['#cdata-section'] : '';
				rate.innerHTML = FORMAT_UTILS.getPriceFormatted((calculateRate.Value['#text'] || ''), (calculateRate.Units['@Symbol'] || ''), (calculateRate.Units['#text'] || ''));
			}
		}
	}

	/**
	 * update
	 * @returns {void} return nothing
	 */
	update() {
		if (this._template === 'carinfo') {
			this._setFinancingData();
		}
	}
}

if (window.customElements.get('leasing-element') === undefined) {
	window.customElements.define('leasing-element', LeasingElement);
}
