/* eslint-disable no-useless-escape */
const template = `
{{? it.templateData.hasRate}}
	<div class="sc-crs-finance-element">
		<input type="hidden" class="sc-vehicle-key" data-vehicle-id="{{=it.vehicleBasic.id}}" value="{{=it.vehicleData.Vehicle.Key}}" />
		{{? it.templateData.disclaimer.hasShortDisclaimer}}
			<span class="audi-copy-m">{{=it.templateData.disclaimer.text1}}</span>
			<span class="sc-detail-carinfo-short-disclaimer sc-j-detail-disclaimer audi-copy-s">
				{{=it.templateData.disclaimer.calculation}}
			</span>
			<br />
			{{? it.templateData.hideChangeRateCTA}}
				{{? it.templateData.alternativeText}}
					<span class="sc-detail-rate audi-copy-m">
						{{=it.templateData.alternativeText}}
					</span>
				{{?}}
			{{??}}
				<a class="audi-link-s audi-link-s--arrow sc-detail-change-rate js-modal-layer-link" data-finance='$m.FinancingJson' data-layer-type="ModalLayerCrsFinanceElement">
					<span class="audi-link-s__text">{{=it.templateData.rate.changeText}}</span>
					<svg class="audi-link-s__icon">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
					</svg>
				</a>
			{{?}}
		{{??}}
			{{? it.templateData.useSpainTemplate}}
				<span class="sc-detail-rate audi-copy-m sc-detail-rate--spain">
					<span>{{=it.templateData.rate.text1}}</span>
					<span class='sc-finance-rate'>{{=it.templateData.rate.value}}</span>
					<span>{{=it.templateData.rate.text2}}</span>
				</span>
			{{??}}
				<span class="sc-detail-rate audi-copy-m">
					<span class="audi-copy-m">{{=it.templateData.rate.text1}}</span>
					<span class='sc-finance-rate'>{{=it.templateData.rate.value}}</span>
					<a href='#fn_rate' class='audi-j-footnote-reference' target='_self'>
						<sup class='audi-footnote-anchor__text'></sup>
					</a>
					<span>
						{{? it.templateData.rate.description && it.templateData.rate.description !== ''}}
							 {{=it.templateData.rate.text2}}
							<span class='sc-finance-product'>{{=it.templateData.rate.description}}</span>
						{{?}}
					</span>
				</span>
			{{?}}
			{{? it.templateData.disclaimer.hasSpecialShortDisclaimer && it.templateData.disclaimer.calculation != ''}}
				<span class="audi-copy-s sc-detail-carinfo-special-disclaimer sc-j-detail-disclaimer">
					{{=it.templateData.disclaimer.calculation}}
				</span>
			{{?}}
			{{? it.templateData.hideChangeRateCTA}}
				{{? it.templateData.alternativeText}}
					<span class="sc-detail-rate audi-copy-m">
						{{=it.templateData.alternativeText}}
					</span>
				{{?}}
			{{??}}
				<a class="audi-link-s audi-link-s--arrow sc-detail-change-rate js-modal-layer-link" data-layer-type="ModalLayerCrsFinanceElement">
					<span class="audi-link-s__text">{{=it.templateData.rate.changeText}}</span>
					<svg class="audi-link-s__icon">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
					</svg>
				</a>
			{{?}}
		{{?}}
	</div>
{{?}}`;

export {template as crsFinanceElementTemplate};
