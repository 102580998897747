import {ScsModelBase} from '../../stockcars-bundle';

class ScFinanceModelClass extends ScsModelBase{
	constructor(name_= "FinanceModel") {
		super(name_);
		this.financeParameters = null;
		this.financeProductId = '';
		this.financeOptions = null;
	}
	/**
	 * updateFinanceData
	 * @param {object} financeData financeData
	 * @returns {void} void
	 */
	updateFinanceData(financeData) {
		this.financeParameters = financeData.parameters;
		this.financeProductId = financeData.productId;
		this.financeOptions = financeData.options;
		this.triggerUpdate();
	}

	/**
	 * getter for financeOptions
	 * @returns {array|null} _financeOptions
	 */
	get financeOptions() {
		return this._financeOptions || null;
	}

	/**
	 * setter for financeOptions
	 * @param {object} financeOptions financeOptions
	 * @returns {void} void
	 */
	set financeOptions(financeOptions) {
		this._financeOptions = financeOptions;
	}

	/**
	 * getter for financeProductId
	 * @returns {string} _financeProductId
	 */
	get financeProductId() {
		return this._financeProductId || '';
	}

	/**
	 * setter for _financeProductId
	 * @param {string} financeProductId financeProductId
	 * @returns {void} void
	 */
	set financeProductId(financeProductId) {
		this._financeProductId = financeProductId;
	}

	/**
	 * getter for financeParameters
	 * @returns {array|null} _financeParameters
	 */
	get financeParameters() {
		return this._financeParameters || null;
	}

	/**
	 * setter for _financeParameters
	 * @param {array} financeParameters financeParameters
	 * @returns {void} void
	 */
	set financeParameters(financeParameters) {
		this._financeParameters = financeParameters;
	}
}

const scFinanceModel = new ScFinanceModelClass();
export {scFinanceModel, ScFinanceModelClass};
