const template = `
	{{? it.hasDealerFinanceFlag && it.hasDealerFinanceFlag === true}}
		<dealer-finance-element class="sc-dealer-finance-element sc-car-tile-finance-element" data-vehicle-id="{{=it.id}}" data-type="{{=it.template}}"></dealer-finance-element>
	{{??}}
		{{? it.layout !== 'compare' && it.layout !== 'carinfo'}}
			<p class="sc-leasing-element-label audi-copy-s">
				<span>{{=window.i18n['sc.details.leasing.label']}}</span> <span class="sc-leasing-element-label-buyable-online">{{=window.i18n['sc.details.leasing.buyable-online']}}</span>
			</p>
		{{?}}

		{{? it.leasing}}
			<p class="sc-leasing-element-value {{? it.layout === 'carinfo'}} audi-copy-m {{??}} audi-copy-s {{?}}">
				{{? it.leasing.value}}
					{{? it.hasLeasingTemplate}}
						{{=it.leasingRateAndDisclaimer}}
						{{? it.leasing.footnote}}
							{{=it.leasing.footnote}}
						{{?}}&nbsp;
					{{??}}
						<span class="sc-leasing-element-value {{? it.layout !== 'carinfo'}} audi-copy-m {{?}}">
							<span class="sc-leasing-element-value-price">{{=it.leasing.value}}</span>
							{{? it.leasing.footnote}}
								{{=it.leasing.footnote}}
							{{?}}&nbsp;
						</span>
						<span>{{=window.i18n['sc.details.leasing.disclaimer.text']}}</span>
					{{?}}
				{{?}}
			</p>
			{{? it.leasing.disclaimer}}
				<p class="sc-leasing-element-disclaimer sc-hyphenate audi-copy-s">
					{{=it.leasing.disclaimer}}
				</p>
			{{?}}
			{{? it.layout === 'carinfo'}}
				<a class="audi-link-s audi-link-s--arrow sc-detail-change-rate js-modal-layer-link" data-layer-type="ModalLayerCrsFinanceElement">
					<span class="audi-link-s__text">{{=window.i18n['sc.details.finance.rate.button.change']}}</span>
					<svg class="audi-link-s__icon">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
					</svg>
				</a>
			{{?}}
		{{?}}
	{{?}}
`;
export {template as leasingElementTemplate};
