import {template} from 'core-utils';
import {financingAllInPricingTemplate} from './financing-allinpricing-template';
import {vehiclesController} from '../../stockcars-bundle';

export default class FinancingAllInPricingElement extends HTMLElement {
	constructor() {
		super();
		if (!this.dataset.vehicleId) {
			return;
		}
		this._data = {
			id: this.dataset.vehicleId
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		await this._initializeProperties();
		if (!!this._showPricingDetailButton) {
			this._render();
		}
		else {
			this.parentNode.removeChild(this); // IE11 remove()
		}
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} void
	 */
	async _initializeProperties() {
		const _vehicleData = await this._getVehicleData(this._data.id);
		const PRICE_TYPES = ['CUSTOM_PRICE_1', 'CUSTOM_PRICE_2', 'CUSTOM_PRICE_3', 'CUSTOM_PRICE_4', 'CUSTOM_TAXATION', 'CUSTOM_TAXATION_2'];
		if (_vehicleData) {
			this._showPricingDetailButton = !SETUPS.get('scopes.hide.prices')
				&& _vehicleData.typedPrices && _vehicleData.typedPrices.filter(price => PRICE_TYPES.includes(price.type)).length;
		}
	}

	/**
	 * get vehicle data
	 * @private
	 * @param {string} id vehicleId
	 * @returns {object} vehicleData json
	 */
	async _getVehicleData(id) {
		try {
			return await vehiclesController.loadVehicleById(id);
		}
		catch (err) {
			console.warn(err);
			throw err;
		}
	}

	/**
	 * render
	 * @private
	 * @returns {void} return nothing
	 */
	_render() {
		this.innerHTML = template.render(financingAllInPricingTemplate, {id: this._data.id});
	}
}

if (window.customElements.get('financing-allinpricing-element') === undefined) {
	window.customElements.define('financing-allinpricing-element', FinancingAllInPricingElement);
}
