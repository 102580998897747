import {modalLayer, ModalLayerContentBase} from 'core-application';
import {template} from 'core-utils';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {modalLayerDealerFinanceTemplate} from './modal-layer-dealer-finance-template';


export default class ModalLayerDealerFinanceElement extends ModalLayerContentBase {

	constructor({vehicleId}) {
		super();
		this._data = {vehicleId};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		this._bindContextToFunctions();
		await this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallbackd
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._render = this._render.bind(this);
		this._getFinanceData = this._getFinanceData.bind(this);
		this._triggerClose = this._triggerClose.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._triggerClose);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._triggerClose);
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			closeButton: '.modal-layer--close-button'
		};
	}

	static get type() {
		return 'ModalLayerDealerFinanceElement';
	}

	/**
	 * private function generating the layer
	 * @param {object} data - the dataset from the clicked element
	 * @returns {HTMLElement} - the ModalLayerCrsFinanceElement layer
	 */
	static async getContent(data) {
		return new ModalLayerDealerFinanceElement(data);
	}

	/**
	 * function get vehicle data
	 * @returns {object} returns finance data object
	 */
	async _getVehicleData() {
		if (this._data && this._data.vehicleId) {
			try {
				await STCK_STORE.waitForStateFromStorePromise({conditionFn: (state) => !!SELECTORS.VEHICLES.getVehiclesMap(state).get(this._data.vehicleId)});
				return SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(this._data.vehicleId) || {};
			}
			catch (e) {
				console.error(e);
			}
		}
	}

	_getDealerFinancingMapping(productIdTextKey) {
		return {
			netcreditamount: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.netCreditAmount.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			},
			duration: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.duration.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.duration.unit`] || ''
			},
			mileage: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.mileage.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.mileage.unit`] || ''
			},
			downpaymentamount: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.downPaymentAmount.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			},
			downpayment: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.downPayment.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			},
			finalrate: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.finalRate.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			},
			painterestrate: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.paInterestRate.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.percentage.unit`] || ''
			},
			effinterestrate: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.effInterestRate.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.percentage.unit`] || ''
			},
			rateinsid: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.rateInsId.label`] || ''
			},
			totalcreditamount: {
				label: window.i18n[`sc.fixed.dealer.${productIdTextKey}.totalCreditAmount.label`] || '',
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			},
			rate: {
				unit: window.i18n[`sc.fixed.dealer.${productIdTextKey}.currency.unit`] || ''
			}
		};
	}

	_getPatchedDealerFinancingParameters(dealerFinancingParameters = [], financingProductIdTextKey = '') {
		const mapping = this._getDealerFinancingMapping(financingProductIdTextKey);
		return dealerFinancingParameters.map(parameter => {
			const _parameter = {...parameter};
			const id = (_parameter.id || '').toLowerCase();
			if (!_parameter.label && mapping[id] && mapping[id].label) {
				_parameter.label = mapping[id].label;
			}
			if ((!_parameter.unit && !_parameter.unitSymbol) && mapping[id] && mapping[id].unit) {
				_parameter.unit = mapping[id].unit;
			}
			return _parameter;
		});
	}

	_getDisclaimer(dealerFinanceData = {}) {
		let formattedDisclaimer = {};
		if (dealerFinanceData && dealerFinanceData.disclaimer && dealerFinanceData.disclaimer.disclaimers) {
			dealerFinanceData.disclaimer.disclaimers.forEach((item, index) => {
				formattedDisclaimer[`disclaimer-${index}`] = formattedDisclaimer[item.disclaimer];
			});
		}
		return formattedDisclaimer;
	}

	_getRateLabel(dealerFinancingData_, productIdTextKey) {
		let label = '';
		if (dealerFinancingData_ && dealerFinancingData_.parameters) {
			const duration = dealerFinancingData_.parameters.find(item => item.id === 'Duration');
			const instalments = window.i18n[`sc.fixed.dealer.${productIdTextKey}.monthly.instalments`] || '';
			label = `${duration.formattedValue} ${instalments}`;
		}
		return label;
	}

	/**
	 * function get finance data
	 * @returns {object} returns finance data object
	 */
	async _getFinanceData() { // eslint-disable-line max-statements
		const vehiclesData = await this._getVehicleData();
		let data = {};

		const productId = vehiclesData.dealerFinancingData.product.id;
		const productIdTextKey = productId === 'LS' ? 'leasing' : 'financing';
		if (vehiclesData && vehiclesData.dealerFinancingData && vehiclesData.dealerFinancingData.valid && vehiclesData.financing) {
			let parameters = this._getPatchedDealerFinancingParameters(vehiclesData.dealerFinancingData.parameters, productIdTextKey);
			const financingSorting = ['netCreditAmount', 'duration', 'mileage', 'downPaymentAmount', 'finalRate', 'paInterestRate', 'effInterestRate', 'totalCreditAmount', 'rate'];
			const leasingSorting = ['duration', 'mileage', 'downPaymentAmount', 'paInterestRate', 'rateInsId', 'rate'];
			const sorting = productId === 'VC' ? financingSorting : leasingSorting;

			let sortedParameters = [];
			sorting.forEach((sort) => {
				let found = false;
				parameters = parameters.filter(item => {
					if (!found && item.id.toLowerCase() === sort.toLowerCase()) {
						sortedParameters.push(item);
						found = true;
						return false;
					}
					else {
						return true;
					}
				});
			});
			data = {
				headline: window.i18n[`sc.fixed.dealer.${productIdTextKey}.headline`] || '',
				intro: window.i18n[`sc.fixed.dealer.${productIdTextKey}.subheadline`] || '',
				product: vehiclesData.dealerFinancingData.product ? vehiclesData.dealerFinancingData.product.label : '',
				parameters: sortedParameters,
				rateLabel: this._getRateLabel(vehiclesData.dealerFinancingData, productIdTextKey),
				disclaimer: vehiclesData.dealerFinancingData.disclaimer && vehiclesData.dealerFinancingData.disclaimer.disclaimers ? vehiclesData.dealerFinancingData.disclaimer.disclaimers : []
			};
		}
		return data;
	}

	/**
	 * function render
	 * @returns {void} returns nothing
	 */
	async _render() {
		const data = await this._getFinanceData();
		this.innerHTML = template.render(modalLayerDealerFinanceTemplate, data);
		this.closeButton = this.querySelector(ModalLayerDealerFinanceElement.defaults.closeButton);
	}
}

modalLayer.registerLayerType(ModalLayerDealerFinanceElement);

if (window.customElements.get('modal-layer-dealer-finance-element') === undefined) {
	window.customElements.define('modal-layer-dealer-finance-element', ModalLayerDealerFinanceElement);
}
