import {dom as DOM_UTILS} from 'core-utils';
import CrsFinanceElement from './crs-finance-element';
import {scModelFinanceForm} from "./sc-model-finance-form";
import {scFinanceModel} from "./sc-finance-model";
import {globalEventEmitter} from "../../stockcars-bundle";

class ScDetailShowCalculatedRate {
	constructor() {
		this._globalEventBus = globalEventEmitter;
		scFinanceModel.subscribe(this);
		this.update();
	}

	static get defaults() {
		return {
			'selectorDetailRate': '.sc-detail-rate .sc-finance-rate',
			'selectorDetailProduct': '.sc-detail-rate .sc-finance-product',
			'selectorDetailVehicleKey': '.sc-vehicle-key',
			'selectorDetailDomWrapper': '.fa-vtp-carinfo',
			'selectorFootnoteDisclaimer': '.audi-footnotes li#fn_rate .sc-fn-text'
		};
	}

	/**
	 * registered update callback method is called upon
	 * scFinanceModel updating all rate fields in the DOM
	 * @returns {void} nothing
	 */
	update() {
		const detailRates = DOM_UTILS.getElementsArray(ScDetailShowCalculatedRate.defaults.selectorDetailRate);
		detailRates.forEach((detailRate) => {
			const detailRateWrapper = DOM_UTILS.closest(detailRate, ScDetailShowCalculatedRate.defaults.selectorDetailDomWrapper);
			this._setRate(detailRateWrapper);
		});
	}

	/**
	 * get the local storage
	 * @method getLocalStorage
	 * @returns {Object} returns localStorage
	 */
	_getLocalStorage() {
		return this._financeStorage;
	}

	/**
	 * private function setting a calculated rate
	 * @param {HTMLElement} context - HTML context element
	 * @returns {undefined}
	 */
	_setRate(context) {
		const vehicleKeyInput = DOM_UTILS.getElement(ScDetailShowCalculatedRate.defaults.selectorDetailVehicleKey, context);
		if (DOM_UTILS.isElement(vehicleKeyInput) && vehicleKeyInput.value !== '') {
			const contextVehicleId = DOM_UTILS.getDataAttribute(vehicleKeyInput, 'vehicle-id');
			const calculatedRate = this._getCalculatedRateFromStorage(contextVehicleId, vehicleKeyInput.value);
			if (!!calculatedRate.rate) {
				this._updateDetailsRate(calculatedRate, context, contextVehicleId);
				this._updateRateFootnoteDisclaimer(calculatedRate);
			}
		}
	}

	/**
	 * get rate object from storage
	 * @param {string} vehicleId - the id of the vehicle
	 * @param {string} vehicleKey - the key of the vehicle
	 * @returns {Object} the rate object
	 */
	_getCalculatedRateFromStorage(vehicleId, vehicleKey) {
		const rateObject = scModelFinanceForm.getRate();
		if (!!rateObject) {
			if (!!rateObject[vehicleId] && !!rateObject[vehicleId][vehicleKey]) {
				return rateObject[vehicleId][vehicleKey];
			}
		}
		return {};
	}

	/**
	 * private function updating the rate on the detail page
	 * @param {Object} calculatedRate - the calculated rate
	 * @param {HTMLElement} context - HTML context element
	 * @param {String} vehicleId - vehicle id
	 * @returns {undefined}
	 */
	_updateDetailsRate(calculatedRate, context, vehicleId) {
		const detailsRate = DOM_UTILS.getElement(ScDetailShowCalculatedRate.defaults.selectorDetailRate, context),
			detailsProduct = DOM_UTILS.getElement(ScDetailShowCalculatedRate.defaults.selectorDetailProduct, context);

		if (DOM_UTILS.isElement(detailsRate)) {
			detailsRate.innerHTML = calculatedRate.rate;
		}
		if (DOM_UTILS.isElement(detailsProduct) && !!calculatedRate.product) {
			if (calculatedRate.productData && calculatedRate.productData['@CampaignID']) {
				const campaign = CrsFinanceElement.getVehicleFinanceCampaign(vehicleId);
				detailsProduct.innerHTML = campaign.filterText || calculatedRate.product;
			}
			else {
				detailsProduct.innerHTML = calculatedRate.product;
			}
		}
	}

	/**
	 * @param {Object} calculatedRate_ - the data for the rate
	 * @returns {void}
	 */
	_updateRateFootnoteDisclaimer(calculatedRate_) {
		let rateFootnotes = [...document.querySelectorAll(ScDetailShowCalculatedRate.defaults.selectorFootnoteDisclaimer)];

		if (calculatedRate_.disclaimer && calculatedRate_.product) {
			rateFootnotes.forEach((footnote) => {
				footnote.innerHTML = calculatedRate_.product + ', ' + calculatedRate_.disclaimer;
			});
		}

	}
}

const scDetailShowCalculatedRate = new ScDetailShowCalculatedRate();
export {ScDetailShowCalculatedRate, scDetailShowCalculatedRate};
