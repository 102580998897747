import {dom as DOM_UTILS, template as TPL_RENDERER} from 'core-utils';
import {ModalLayerContentBase} from 'core-application';
import {ACTIONS, SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {RequestController, svdModel} from '../../stockcars-bundle';
import {FORMAT_UTILS, OBJECT_UTILS} from '../../stockcars-utils-bundle';
import {scFinanceFormTechnicalErrorTemplate as TECHNICAL_ERROR_TEMPLATE} from './templates/sc-finance-form-technical-error-template';
import {scFinanceFormErrorTemplate as ERROR_TEMPLATE} from './templates/sc-finance-form-error-template';
import {scFinanceFormTemplate as FORM_TEMPLATE} from './templates/sc-finance-form-template';
import {scFinanceModel} from './sc-finance-model';
import {scFinanceProductFormTemplate as PRODUCT_TEMPLATE} from './templates/sc-finance-product-form-template';
import {scModelFinanceForm as FF_MODEL} from './sc-model-finance-form';
import {mergeDeep} from './mergeDeep';
import {getCalculationDisclaimer, getDisclaimerFromFormData} from "./utils";

export default class ScControllerFinanceFormElement extends ModalLayerContentBase {
	requestData = {};
	productRequestData = {};
	vehicleId = null;

	constructor(data_ = {}, productData = {}, vehicleId) {
		super();
		this.requestData = data_;
		this.productRequestData = productData;
		this.vehicleId = vehicleId;
	}

	connectedCallback() {
		this._bindContextToFunctions();
		this._addEvents();
		this.generateForm();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	static get defaults() {
		return {
			formRowWrapper: '.sc-finance-form-row',
			selectorDataWrapper: '.sc-finance-form-wrapper',
			selectorDetailRateForm: '#sc-finance-form-rate',
			selectorErrorCloseButton: '.js-finance-form-error-close',
			selectorResponseErrorContainer: '.sc-finance-form-response-error',
			selectorForm: '#sc-finance-form',
			selectorFormAutoPostBack: '.js-sc-finance-form-autopostback',
			selectorFormButtonCalculate: '.js-sc-finance-form-calculate',
			selectorFormButtonUpdata: '.js-sc-finance-form-update',
			selectorFormDecimal: '.js-sc-finance-form-Decimal',
			selectorFormDisclaimerText: '.sc-finance-form-disclaimers .sc-j-fn-disclaimer-text',
			selectorFormInfoHelperPopup: '.sc-finance-form-help-popup',
			selectorFormInfoHelperPopupCloseButton: '.sc-finance-form-help-popup-close-button',
			selectorFormInfoIcon: '.js-sc-finance-form-info-icon',
			selectorFormInfoLabel: '.sc-finance-form-help-label',
			selectorFormInteger: '.js-sc-finance-form-Integer',
			selectorFormLayer: '.modal-layer__inner',
			selectorFormProductItems: '[name="sc-finance-product-radio"]',
			selectorFormRelevantFields: '.sc-finance-form-relevant',
			selectorLayer: '.modal-layer',
			selectorProductDescription: '.sc-finance-form-product-description',
			selectorDisclaimer: '.sc-j-detail-disclaimer',
			selectorCurrentProduct: '.js-sc-finance-form-product:checked + .audi-radiobutton-label',
			selectorCurrentProductInput: '.js-sc-finance-form-product:checked'
		};
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._showInfoPopup = this._showInfoPopup.bind(this);
		this._handleClickOutsidePopUpHelper = this._handleClickOutsidePopUpHelper.bind(this);
		this._closeInfoPopup = this._closeInfoPopup.bind(this);
		this._onChangeProduct = this._onChangeProduct.bind(this);
		this._onClickCalculateButton = this._onClickCalculateButton.bind(this);
		this._onClickUpdateDetails = this._onClickUpdateDetails.bind(this);
		this._onChangeAutoPostBackField = this._onChangeAutoPostBackField.bind(this);
		this._onChangeIntegerField = this._onChangeIntegerField.bind(this);
		this._onChangeDecimalField = this._onChangeDecimalField.bind(this);
		this._onClickErrorLayerClose = this._onClickErrorLayerClose.bind(this);
		this._renderDataForm = this._renderDataForm.bind(this);
		this._holdFinanceData = this._holdFinanceData.bind(this);
	}

	/**
	 * private function set Disclaimer
	 * @returns {undefined}
	 */
	_setDisclaimer() {
		const disclaimerText = this.querySelector(ScControllerFinanceFormElement.defaults.selectorFormDisclaimerText);
		this._disclaimerText = !!disclaimerText ? disclaimerText.getAttribute('data-fn-disclaimer') : '';
		this._downPayment = '';
	}

	/**
	 * private function adding all event
	 * @returns {undefined}
	 */
	_addEvents() {
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormInfoIcon, this._showInfoPopup);
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormInfoLabel, this._showInfoPopup);
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopupCloseButton, this._closeInfoPopup);
		_domEventDelegate.on('change', ScControllerFinanceFormElement.defaults.selectorFormProductItems, this._onChangeProduct);
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormButtonCalculate, this._onClickCalculateButton);
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormButtonUpdata, this._onClickUpdateDetails);
		_domEventDelegate.on('change', ScControllerFinanceFormElement.defaults.selectorFormAutoPostBack, this._onChangeAutoPostBackField);
		_domEventDelegate.on('keyup', ScControllerFinanceFormElement.defaults.selectorFormInteger, this._onChangeIntegerField);
		_domEventDelegate.on('keyup', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onChangeDecimalField);
		_domEventDelegate.on('focusin', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onFocusInDecimalField);
		_domEventDelegate.on('focusout', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onFocusOutDecimalField);
		_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorErrorCloseButton, this._onClickErrorLayerClose);
	}

	/**
	 * private function removing all event
	 * @returns {undefined}
	 */
	_removeEvents() {
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormInfoIcon, this._showInfoPopup);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormInfoLabel, this._showInfoPopup);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormLayer, this._handleClickOutsidePopUpHelper);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopupCloseButton, this._closeInfoPopup);
		_domEventDelegate.off('change', ScControllerFinanceFormElement.defaults.selectorFormProductItems, this._onChangeProduct);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormButtonCalculate, this._onClickCalculateButton);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormButtonUpdata, this._onClickUpdateDetails);
		_domEventDelegate.off('change', ScControllerFinanceFormElement.defaults.selectorFormAutoPostBack, this._onChangeAutoPostBackField);
		_domEventDelegate.off('keyup', ScControllerFinanceFormElement.defaults.selectorFormInteger, this._onChangeIntegerField);
		_domEventDelegate.off('keyup', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onChangeDecimalField);
		_domEventDelegate.off('focusin', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onFocusInDecimalField);
		_domEventDelegate.off('focusout', ScControllerFinanceFormElement.defaults.selectorFormDecimal, this._onFocusOutDecimalField);
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorErrorCloseButton, this._onClickErrorLayerClose);
	}

	_showInfoPopup(event) {
		const label = event.target;
		const popUpHelper = DOM_UTILS.siblings(label, ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopup)[0];
		if (!!popUpHelper) {
			const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
			_domEventDelegate.on('click', ScControllerFinanceFormElement.defaults.selectorFormLayer, this._handleClickOutsidePopUpHelper);
			if (popUpHelper.classList.contains('js-sc-show-finance-popup-helper')) {
				popUpHelper.classList.remove('js-sc-show-finance-popup-helper');
			}
			else {
				popUpHelper.classList.add('js-sc-show-finance-popup-helper');
			}
		}
	}

	_closeInfoPopup(event) {
		const closeButton = event.target;
		const popUpHelper = DOM_UTILS.closest(closeButton, ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopup);
		if (popUpHelper) {
			if (popUpHelper.classList.contains('js-sc-show-finance-popup-helper')) {
				popUpHelper.classList.remove('js-sc-show-finance-popup-helper');
			}
		}
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.off('click', ScControllerFinanceFormElement.defaults.selectorFormLayer, this._handleClickOutsidePopUpHelper);
	}

	_handleClickOutsidePopUpHelper(event) {
		const infoPopUpHelpers = DOM_UTILS.getElementsArray(ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopup, this);
		const clickedOutsidePopUp = DOM_UTILS.isElementOutsideOfElementWithSelector(event.target, ScControllerFinanceFormElement.defaults.selectorFormInfoHelperPopup);
		const clickedOutsideInfoIcon = DOM_UTILS.isElementOutsideOfElementWithSelector(event.target, ScControllerFinanceFormElement.defaults.selectorFormInfoIcon);
		const clickedOutsideInfoLabel = DOM_UTILS.isElementOutsideOfElementWithSelector(event.target, ScControllerFinanceFormElement.defaults.selectorFormInfoLabel);

		if (clickedOutsidePopUp && clickedOutsideInfoIcon && clickedOutsideInfoLabel) {
			infoPopUpHelpers.forEach((infoPopUpHelper) => {
				if (infoPopUpHelper.classList.contains('js-sc-show-finance-popup-helper')) {
					infoPopUpHelper.classList.remove('js-sc-show-finance-popup-helper');
				}
			});
		}
	}

	/**
	 * private function escaping the requestdata
	 * @param {string} domain - the domain of the request
	 * @param {Object} vehicle - the vehicle
	 * @returns {string} returns the encoded and strinmgified request data
	 */
	_escapeRequestData(requestData) {
		return encodeURI(JSON.stringify(requestData));
	}

	/**
	 * private function unescaping the requestdata
	 * @param {string} requestDataString - the stringified and escaped requestdata
	 * @returns {JSONObject} returns requestdata object
	 */
	_unescapeRequestData(requestDataString) {
		if (!!requestDataString) {
			return JSON.parse(decodeURI(requestDataString));
		}
		return {};
	}

	/**
	 * private function escaping user input
	 * @param {string} string - the sring to be escaped
	 * @returns {string} the escaped string
	 */
	_escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '').replace(',', '.');
	}

	/**
	 * private function getting the data form the form
	 * @param {DomElement} form - the data form
	 * @returns {Array} returns the relevant formdata
	 */
	_getRelevantDataFromForm(form) {
		let data = [];
		const formfields = DOM_UTILS.getElementsArray(ScControllerFinanceFormElement.defaults.selectorFormRelevantFields, form);
		formfields.forEach((field) => {
			if (field.classList.contains('radio')) {
				const radio = field.querySelector('input:checked');
				if (DOM_UTILS.isElement(radio)) {
					data.push({'@ID': field.id, '#text': this._escapeRegExp(radio.value)});
				}
			}
			else if (field.classList.contains('js-sc-finance-form-Decimal')) {
				data.push({
					'@ID': field.id,
					'#text': (parseFloat(field.dataset.decimalValue).toString() || parseFloat(field.value).toString())
				});
			}
			else {
				data.push({'@ID': field.id, '#text': this._escapeRegExp(field.value)});
			}
		});
		return data;
	}

	/**
	 * private function changing the product and rendering the new form
	 * @param {Event} event - the change event
	 * @returns {undefined}
	 */
	_onChangeProduct(event) {
		const productId = event.target.value,
			requestDataString = DOM_UTILS.getDataAttribute(event.target, 'attributes'),
			requestData = this._unescapeRequestData(requestDataString);

		this._showCurrentProductDescription(event.target);

		FF_MODEL.getDefaultDataForProduct({...requestData, ...{Product: {'@ID': productId}}}).then((formData) => {
			this._renderDataForm(formData, requestDataString, productId, false);
		});
	}

	/**
	 * private function show the selected productdescription
	 * @param {DOMElement} input - the selected product input
	 * @returns {undefined}
	 */
	_showCurrentProductDescription(input) {
		let productDescription, productDescriptionWrapper;

		if (DOM_UTILS.isElement(input)) {
			productDescription = DOM_UTILS.getDataAttribute(input, 'description');
			productDescriptionWrapper = this.querySelector(ScControllerFinanceFormElement.defaults.selectorProductDescription);
			if (!!productDescription) {
				productDescriptionWrapper.innerHTML = productDescription;
			}
		}
	}

	_getSelectedProduct() {
		const input = document.querySelector(ScControllerFinanceFormElement.defaults.selectorCurrentProductInput);
		if (!input) {
			return {'@ID': this.requestData.Product['@ID']};
		}
		return input.dataset && input.dataset.campaign ? {
			'@ID': input.value,
			'@CampaignID': input.dataset.campaign
		} : {'@ID': input.value};
	}

	/**
	 * private function rerendering the form
	 * @returns {undefined}
	 */
	_reRenderForm() {
		const form = DOM_UTILS.getElement(ScControllerFinanceFormElement.defaults.selectorForm, this),
			formData = this._getRelevantDataFromForm(form),
			requestDataString = DOM_UTILS.getDataAttribute(form, 'requestdata'),
			productId = DOM_UTILS.getDataAttribute(form, 'productid'),
			product = this._getSelectedProduct(),
			requestData = this._unescapeRequestData(requestDataString);

		if (formData.length) {
			product.Parameter = formData;
		}

		const request = {...requestData, ...{Product: {...product, ...{Parameter: [...formData]}}}};
		FF_MODEL.getDataForProduct(request, this.vehicleId).then((formData_) => {
			if (formData_ && formData_.Error) {
				this._renderErrorFromCrsResponse(formData_.Error);
			}
			else if (formData_.Parameters && formData_.Parameters.Group) {
				STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
					vehicleId: this.vehicleId,
					financing: {formParameter: formData, productParameter: product}
				}));
				const groupParameter = (Array.isArray(formData_.Parameters.Group) && formData_.Parameters.Group.filter(data => data['@Name'] === 'Financing').length) ? formData_.Parameters.Group.filter(data => data['@Name'] === 'Financing')[0].Parameter : formData_.Parameters.Group.Parameter;
				this._holdFinanceData(groupParameter, productId, requestData.Vehicle.Options);
				this._renderDataForm(formData_, requestDataString, productId, true);
			}
		}).catch(
			(reject) => {
				this._renderDataFormError(reject); // errorhandling
			}
		);

		this.tmpRateObject = request;
	}

	/**
	 * @param {string} domain_ - the domain of the request
	 * @param {Object} product_ - the product
	 * @param {Object} formData_ - the productParameter
	 * @param {Object} vehicle_ - the vehicle
	 * @param {Object} dealer_ - the dealer object
	 * @returns {void}
	 */
	async _setRateDisclaimer(request) { // eslint-disable-line max-params
		try {
			const vehicleId = this.vehicleId;
			let data_ = await FF_MODEL.getCalculateRateData(request).catch((reject) => {
				console.error(reject);
			});
			let productLabel = document.querySelector(ScControllerFinanceFormElement.defaults.selectorCurrentProduct)?.innerHTML || data_.Response.Parameters?.Group?.Label || '';
			let calculationDisclaimer = data_.Response.Parameters.CalculationDisclaimer ? this._getValueString(data_.Response.Parameters.CalculationDisclaimer) : '',
				productDisclaimer = data_.Response.Parameters.ProductDisclaimer ? this._getValueString(data_.Response.Parameters.ProductDisclaimer) : '',
				globalDisclaimer = data_.Response.Parameters.GlobalDisclaimer ? this._getValueString(data_.Response.Parameters.GlobalDisclaimer) : '';
			this.rateDisclaimer = `${productLabel ? productLabel + ': ' : ''}${calculationDisclaimer ? calculationDisclaimer + '<br/><br>' : ''}${productDisclaimer ? productDisclaimer + '<br/><br>' : ''}${globalDisclaimer ? globalDisclaimer + '<br/><br>' : ''}`;
			const context = document.querySelector(`.fa-vtp-carinfo[data-vehicle-id='${vehicleId}']`);
			this._setDisclaimerText(getCalculationDisclaimer(data_.Response), context);

			STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
				vehicleId: vehicleId,
				financing: {calculateData: data_}
			}));
		}
		catch (e) {
			console.error(e);
		}
	}

	/**
	 * public setter to update vehicle items and notify observers
	 * @param {string} disclaimer_ calculation disclaimer
	 * @param {HTMLElement} context_ - context for the disclaimer
	 * @returns {void} nothing
	 */
	_setDisclaimerText(disclaimer_ = '', context_ = document) {
		let disclaimerElement = context_.querySelector(ScControllerFinanceFormElement.defaults.selectorDisclaimer);
		if (disclaimerElement && disclaimer_ && disclaimer_ !== '') {
			disclaimerElement.innerHTML = disclaimer_;
		}
	}

	/**
	 * _holdFinanceData hold finance data to save when user change finance data
	 * @param {array} parameters financing parameters
	 * @param {string} productId productId
	 * @param {object} options options
	 * @private
	 * @returns {void} void
	 */
	_holdFinanceData(parameters, productId, options) {
		this._financeData = {
			parameters,
			productId,
			options
		};
	}

	/**
	 * private function calculating the new rate
	 * @returns {undefined}
	 */
	_onClickCalculateButton() {
		this._reRenderForm();
	}

	/**
	 * private function rerendering the form
	 * @returns {undefined}
	 */
	_onChangeAutoPostBackField() {
		this._reRenderForm();
	}

	/**
	 * private function handling the update click
	 * @param {Event} event - the click event
	 * @returns {undefined}
	 */
	async _onClickUpdateDetails() {
		const rateInForm = this.querySelector(ScControllerFinanceFormElement.defaults.selectorDetailRateForm),
			form = this.querySelector(ScControllerFinanceFormElement.defaults.selectorForm),
			requestDataString = DOM_UTILS.getDataAttribute(form, 'requestdata'),
			requestData = this._unescapeRequestData(requestDataString),
			rateObject = {};
		rateObject.rate = DOM_UTILS.getDataAttribute(rateInForm, 'rate');
		rateObject.product = DOM_UTILS.getDataAttribute(rateInForm, 'product');
		rateObject.productData = this._getSelectedProduct();
		rateObject.disclaimer = this.rateDisclaimer;

		FF_MODEL.setCaculatedRate(rateObject, this.vehicleId, requestData.Vehicle.Key);

		await this._setRateDisclaimer(this.tmpRateObject);

		scFinanceModel.updateFinanceData(this._financeData);


		if (!SETUPS.get('scopes.nemo.ONEFOOTER_INCLUDE')) {
			this._patchRateFootnotes(this.rateDisclaimer);
		}
		else {
			if (!!this.rateDisclaimer) {
				const patchFootnotes = new CustomEvent('patch:footnotes', {
					detail: {
						footnotes: [{Key: 'fn_rate', Text: this.rateDisclaimer}]
					}
				});
				document.dispatchEvent(patchFootnotes);
			}
		}

		this._closeLayer(form);
	}

	/**
	 * private function patch rate footnotes
	 * @param {string} disclaimerText_ - text of disclaimer
	 * @returns {void} returns nothing
	 */
	_patchRateFootnotes(disclaimerText_) {
		const context = this._getContext();
		if (!!disclaimerText_) {
			const rateFootnotes = [...context.querySelectorAll('[id$="fn_rate"] .sc-fn-text')];
			rateFootnotes.forEach((rate) => {
				rate.innerHTML = disclaimerText_;
			});
		}
	}

	/** Validation */

	/**
	 * private function handling the input of an integer field
	 * @param {Event} event - the change event
	 * @returns {undefined}
	 */
	_onChangeIntegerField(event) {
		const input = event.target,
			value = input.value;
		input.value = parseInt(value, 10);
	}

	/**
	 * private function handling the input of an intetger field
	 * @param {Event} event - the change event
	 * @returns {undefined}
	 */
	_onChangeDecimalField(event) {
		const input = event.target,
			value = input.value;
		input.value = (!isNaN(input.value) && input.value.length > 0) ? parseFloat(value) : '';
	}

	/**
	 * private function handling the focusin decimal field
	 * @param {Event} event - the change event
	 * @returns {undefined}
	 */
	_onFocusInDecimalField(event) {
		const input = event.target,
			formatted = input.value;
		let value = input.dataset;
		value = value.decimalValue ? value.decimalValue : '';
		this._downPayment = {'value': value, 'formatted': formatted};
		input.value = '';
	}

	/**
	 * private function handling the focusout decimal field
	 * @param {Event} event - the change event
	 * @returns {undefined}
	 */
	_onFocusOutDecimalField(event) {
		const input = event.target,
			value = input.value;
		if (value.length === undefined || value.length === 0) {
			input.value = this._downPayment.formatted;
		}
		else {
			input.dataset.decimalValue = value;
		}
	}

	/**
	 * private function closing the errorlayer
	 * @returns {undefined}
	 */
	_onClickErrorLayerClose() {
		this._closeLayer();
	}

	/**
	 * private function closing the layer
	 * @returns {void} nothing
	 */
	_closeLayer() {
		this._triggerClose();
	}

	/**
	 * private function generating the initial form
	 * @param {string} domain_ - the domain for the request e.g. VTP.AUDI.IE
	 * @param {Object} vehicle_ - the vehicle for the request e.g. {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
	 * @param {Object} product_ - the product for the request e.g. {"@ID": "BQ12AA", "Label": "Vario"}
	 * @returns {undefined}
	 */
	async generateForm() { // eslint-disable-line max-statements
		try {
			if (!this.requestData) {
				throw new Error('corrupted finance data!');
			}

			let defaultProductData = {...this.requestData.Product};
			let productData = this.requestData.Product;
			if (this.productRequestData) {
				productData = await FF_MODEL.getProducts(this.productRequestData, this.vehicleId);
				defaultProductData = (productData.defaultProduct && productData.defaultProduct['@ID']) ? {'@ID': productData.defaultProduct['@ID']} : productData.defaultProduct;
			}

			const storedProduct = this._patchProductData(productData, defaultProductData, this.vehicleId);
			defaultProductData['@ID'] = storedProduct;
			defaultProductData = this._patchFormParameter(defaultProductData, this.vehicleId) || defaultProductData;
			const financeCampaign = SELECTORS.FINANCING.getFinancingCampaignForVehicleId(STCK_STORE.state, this.vehicleId);
			defaultProductData = this._addProductCampaign(defaultProductData, financeCampaign);

			const requestData = JSON.parse(JSON.stringify(this.requestData));
			const defaultRawData = await FF_MODEL.getDefaultRawDataForProduct(mergeDeep(requestData, {Product: defaultProductData}));
			const defaultFormData = FF_MODEL.prepareDefaultDataForProduct(defaultRawData);
			productData = await this._addCampaignAsProduct(productData, financeCampaign, this.vehicleId);

			this._renderForm(productData, defaultFormData, this._escapeRequestData(this.requestData), defaultProductData['@ID'], storedProduct);
			this._setDisclaimer();
		}
		catch (err) {
			this._renderDataFormError(err);
		}
	}

	/**
	 * isCampaignActive
	 * @param {object} campaign_ campaign_
	 * @returns {boolean} is active
	 */
	_isCampaignActive(campaign_) {
		const {startDate, endDate} = campaign_;
		return !!((startDate && endDate) && (Number(startDate) <= Date.now() && Number(endDate) >= Date.now()));
	}

	async _getAllActiveCampaignsFromScs() {
		const svd = await svdModel.getSvdVersion();
		const campaigns = await RequestController.fetchCampaigns(svd);
		return campaigns;
	}

	async _addCampaignAsProduct(productData_, financeCampaign_, vehicleId) { // eslint-disable-line max-statements
		if (financeCampaign_ && Object.keys(financeCampaign_).length) {
			const vehicleData = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(vehicleId);
			const activeCampaigns = await this._getAllActiveCampaignsFromScs().catch(() => {
				return [];
			});
			const vehiclesActiveCampaigns = vehicleData.campaigns ? vehicleData.campaigns.filter(campaign => {
				return campaign.type && campaign.type === 'FINANCING' && campaign.typeDetail && activeCampaigns.some(activeCampaign => activeCampaign.campaignId === campaign.campaignId) && this._isCampaignActive(campaign);
			}) : [];

			let product = SELECTORS.FINANCING.getFinancingProductForProductId(STCK_STORE.state, vehicleId, financeCampaign_.typeDetail);
			const productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(STCK_STORE.state, vehicleId);
			product = product.length ? product[0] : product;
			if (product && product.Label) {
				vehiclesActiveCampaigns.forEach((campaign_, index) => {
					let financeProduct = OBJECT_UTILS.deepCopy(product);
					financeProduct.Label = campaign_.filterText;
					financeProduct.value = campaign_.typeDetail;
					financeProduct['@ID'] = `${campaign_.typeDetail}-${campaign_.campaignId}`;
					if (!productParameter || productParameter && productParameter['@CampaignID']) {
						financeProduct['@Default'] = (vehiclesActiveCampaigns.length >= 1 && campaign_.campaignId === financeCampaign_.campaignId) ? 'Yes' : 'No';
						if (index === 0) {
							productData_.Product.forEach(prod => {
								prod['@Default'] = 'No';
							});
						}
					}
					else {
						productData_.Product.forEach(prod => {
							prod['@Default'] = 'No';
						});
						const currentProduct = productData_.Product.filter(prod => prod['@ID'] === productParameter['@ID']);
						if (currentProduct.length) {
							currentProduct[0]['@Default'] = 'Yes';
						}
						financeProduct['@Default'] = 'No';
					}
					financeProduct.Campaign = financeCampaign_.campaignId;
					productData_.Product.push(financeProduct);
				});
			}
		}
		return productData_;
	}

	_addProductCampaign(defaultProduct_, financeCampaign_) {
		if (financeCampaign_ && Object.keys(financeCampaign_).length) {
			defaultProduct_['@ID'] = financeCampaign_.typeDetail;
			defaultProduct_['@CampaignID'] = financeCampaign_.campaignId;
		}
		return defaultProduct_;
	}

	_patchFormParameter(defaultProductData, vehicleId) {
		const formParameter = SELECTORS.FINANCING.getFormParameterForVehicleId(STCK_STORE.state, vehicleId);
		if (formParameter && Object.keys(formParameter).length > 0) {
			return {...defaultProductData, Parameter: formParameter};
		}
		return defaultProductData;
	}

	_patchProductData(productData, defaultProductData, vehicleId) {
		let storedProduct = defaultProductData['@ID'];
		if (productData && productData.Product && productData.Product.length > 0) {
			const productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(STCK_STORE.state, vehicleId);
			if (productParameter && Object.keys(productParameter).length > 0) {
				productData.Product.forEach(product => {
					if (product['@ID'] === productParameter['@ID']) {
						storedProduct = product['@ID'];
					}
				});
			}
		}
		return storedProduct;
	}

	/**
	 * private function rendering the form into the given wrapper
	 * @param {Object} productData - all products
	 * @param {Object} defaultFormData - the form to be rendered
	 * @param {String} requestDataString - the requestData for formchanges
	 * @param {String} defaultProductID_ - the id of the default product
	 * @param {String} storedProduct - the id of stored product
	 * @returns {void}
	 */
	_renderForm(productData, defaultFormData, requestDataString, defaultProductID_, storedProduct) { // eslint-disable-line max-params
		this._renderProductForm(productData, requestDataString, storedProduct);
		this._renderDataForm(defaultFormData, requestDataString, defaultProductID_, false);
	}

	/**
	 * private function rendering the product part
	 * @param {Object} productData - the data needed to render der product tabs/radiobutton
	 * @param {string} requestDataString - the requestData for formchanges
	 * @param {String} storedProduct - the id of stored product
	 * @returns {void}
	 */
	_renderProductForm(productData, requestDataString, storedProduct) {
		const templateData = {productData: productData, requestData: requestDataString, stored: storedProduct},
			productTemplate = TPL_RENDERER.render(PRODUCT_TEMPLATE, templateData);

		if (!!productTemplate) {
			this.innerHTML = productTemplate;
		}
	}

	/**
	 * private function rendering the dataform part
	 * @param {Object} formData - the data needed to render der dataform
	 * @param {string} requestDataString - the requestData for formchanges
	 * @param {string} productId - the id of the product
	 * @param {boolean} isCalculated - whether the form was already calculated
	 * @returns {void}
	 */
	_renderDataForm(formData, requestDataString, productId, isCalculated) {
		const templateData = {
				formData: this._generateFormData(formData),
				requestData: requestDataString,
				productId: productId,
				isCalculated: isCalculated
			},
			formTemplate = TPL_RENDERER.render(FORM_TEMPLATE, templateData),
			dataFormWrapper = this.querySelector(ScControllerFinanceFormElement.defaults.selectorDataWrapper);
		if (!!formTemplate) {
			const formElement = DOM_UTILS.createElementsFromHtmlString(formTemplate)[0];
			if (dataFormWrapper) {
				dataFormWrapper.parentNode.replaceChild(formElement, dataFormWrapper);
			}
			else {
				this.appendChild(formElement);
			}
		}
	}

	/**
	 * private function generate form data
	 * @param {object} formData_ - the raw form data
	 * @returns {object} formData - the data needed to render the dataform
	 */
	_generateFormData(formData_) {
		let formData = {};
		const formDataParameters = formData_.Parameters;
		const globalDisclaimer = SETUPS.get('scopes.financing.global.disclaimer.from.result') ? getDisclaimerFromFormData('GlobalDisclaimer', formData_.Result) : getDisclaimerFromFormData('GlobalDisclaimer', formDataParameters);
		if (formDataParameters) {
			formData.description = (formDataParameters.Description ? this._getValueString(formDataParameters.Description) : '') || (formDataParameters.Group && formDataParameters.Group.length ? this._getValueString(formDataParameters.Group[0].Label) : '');
			formData.disclaimer = {
				globalDisclaimer: globalDisclaimer,
				groupDisclaimer: ((formDataParameters.Group && formDataParameters.Group.Disclaimer) ? this._getValueString(formDataParameters.Group.Disclaimer['#cdata-section']) : ''),
				productDisclaimer: getDisclaimerFromFormData('ProductDisclaimer', formDataParameters),
				calculationDisclaimer: getDisclaimerFromFormData('CalculationDisclaimer', formDataParameters),
				rateDetails: (formDataParameters.RateDetails ? this._getValueString(formDataParameters.RateDetails['#cdata-section']) : '')
			};
			if (formDataParameters.Group) {
				formData.data = [];
				formDataParameters.Group.forEach(group => {
					let groupData = {
						name: (group['@Name'] ? group['@Name'] : ''),
						label: (group.Label ? this._getValueString(group.Label) : ''),
						parameter: (group.Parameter ? this._getFormParameter((group.Parameter instanceof Array ? group.Parameter : [group.Parameter])) : [])
					};
					formData.data.push(groupData);
				});
			}
		}
		return formData;
	}

	/**
	 * private function get for parameter
	 * @param {array} parameter_ - raw parameter
	 * @returns {object} parameter - parameter needed to render the dataform
	 */
	_getFormParameter(parameter_ = []) {
		const parameter = [];
		parameter_.forEach(param_ => {
			const param = {
				id: (param_['@ID'] ? param_['@ID'] : ''),
				relevant: (param_['@Relevant'] ? !!param_['@Relevant'] : false),
				label: this._getDisplayLabel(param_.Label, param_.Note),
				help: (param_.Help ? this._getValueString(param_.Help) : ''),
				hasHelp: !!param_.Help,
				'default': (param_.Default ? param_.Default : ''),
				unit: {
					symbol: ((param_.Units && param_.Units['@Symbol']) ? (param_.Units && param_.Units['@Symbol']) : ''),
					text: ((param_.Units && param_.Units['#text']) ? (param_.Units && param_.Units['#text']) : '')
				},
				displayUnit: this._getDisplayUnit(param_.Units),
				data: (param_.Data ? this._getParamData(((param_.Data && Array.isArray(param_.Data)) ? param_.Data : [param_.Data]), param_.Units) : []),
				hasData: !!param_.Data,
				control: {
					type: ((param_.Control && param_.Control['@Type']) ? (param_.Control['@Type']) : ''),
					style: ((param_.Control && param_.Control['@Style']) ? (param_.Control['@Style']) : ''),
					dataType: ((param_.Control && param_.Control['@DataType']) ? (param_.Control['@DataType']) : ''),
					maxInputLength: ((param_.Control && param_.Control['@Length']) ? (param_.Control['@Length']) : ''),
					autoPostBack: ((param_.Control && param_.Control['@AutoPostBack']) ? (!!param_.Control['@AutoPostBack']) : '')
				}
			};
			parameter.push(param);
		});
		return parameter;
	}

	/**
	 * private function get display label
	 * @param {string} label_ - item label
	 * @param {string} note_ - item note
	 * @returns {string} label - generated label
	 */
	_getDisplayLabel(label_ = '', note_ = '') {
		let label = '';
		if (!!label_) {
			label = !!note_ ? (label_ + ' ' + note_) : label_;
		}
		return label;
	}

	/**
	 * private function get display unit
	 * @param {object|string} units_ - units (object or string is possible)
	 * @returns {string} unit - parameter needed to render the dataform
	 */
	_getDisplayUnit(units_ = '') {
		let unit = '';
		if (typeof units_ === 'string') {
			unit = units_;
		}
		else {
			if (units_['@Symbol']) {
				unit = units_['@Symbol'];
			}
			else if (units_['#text']) {
				unit = units_['#text'];
			}
			else {
				unit = '';
			}
		}
		return unit;
	}

	/**
	 * private function get param data
	 * @param {array} paramData_ - parameter data
	 * @param {string} units_ - parameter units
	 * @returns {array} paramData - parameter data
	 */
	_getParamData(paramData_ = [], units_ = '') {
		const paramData = [];
		paramData_.forEach(param => {
			const paramValue = ((param['@Value']) ? (param['@Value']) : ''),
				paramText = ((param['#text']) ? (param['#text']) : ''),
				unitSymbol = ((units_['@Symbol']) ? (units_['@Symbol']) : ''),
				unitText = ((units_['#text']) ? (units_['#text']) : '');
			// eslint-disable-next-line no-nested-ternary
			const displayUnit = unitSymbol !== '' ? unitSymbol : (unitText !== '' ? unitText : '');
			const currency = SETUPS.get('nemo.locale.currency');
			const isCurrency = unitSymbol === currency || unitText === currency;
			// eslint-disable-next-line no-nested-ternary
			const formattedValue = isCurrency ? FORMAT_UTILS.getPriceFormatted(paramText, unitSymbol, unitText) : `${paramText || ''} ${displayUnit}`;
			const data = {
				type: ((unitSymbol || unitText) === '%' ? 'percentage' : 'price'),
				value: paramValue,
				text: paramText,
				formattedValue
			};
			paramData.push(data);
		});
		return paramData;
	}

	/**
	 * private function get param data
	 * @param {object|string} value_ - value (object or string is possible)
	 * @returns {object} returns value string
	 */
	_getValueString(value_ = '') {
		return ((typeof value_ === 'string') ? value_ : value_[Object.keys(value_)[0]]);
	}

	/**
	 * private function get context
	 * @returns {void} returns nothing
	 */
	_getContext() {
		const layer = this.querySelector(ScControllerFinanceFormElement.defaults.selectorLayer);
		return layer ? layer : document.body;
	}

	/**
	 * private function render error from CRS response
	 * @param {object} errorObject_ - error object
	 * @returns {void} returns nothing
	 */
	_renderErrorFromCrsResponse(errorObject_ = {}) {
		const error = (errorObject_ && errorObject_['@ErrorType'] && errorObject_['@ErrorType'] === 'User' && !!errorObject_.Description) ? errorObject_ : {};
		const errorTemplate = TPL_RENDERER.render(ERROR_TEMPLATE, error);
		const errorContainer = document.querySelector(ScControllerFinanceFormElement.defaults.selectorResponseErrorContainer);

		if (!!errorTemplate) {
			errorContainer.innerHTML = errorTemplate;
		}
		const modalLayer = document.querySelector(ScControllerFinanceFormElement.defaults.selectorLayer);
		DOM_UTILS.animateElementY(0, modalLayer, 500);
	}

	/**
	 * private function render data form error
	 * @param {object} error - error
	 * @returns {void} returns nothing
	 */
	_renderDataFormError(error) {
		console.warn(error);
		const errorTemplate = TPL_RENDERER.render(TECHNICAL_ERROR_TEMPLATE, null);

		if (!!errorTemplate) {
			this.innerHTML = errorTemplate;
		}
	}
}

if (window.customElements.get('sc-finance-form-element') === undefined) {
	window.customElements.define('sc-finance-form-element', ScControllerFinanceFormElement);
}
