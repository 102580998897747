const exports = `
	{{? it.headline && it.headline !== ''}}
		<div class="sc-finance-layer-buttons-headline audi-headline-order-3">{{=it.headline}}</div>
	{{?}}
	{{? it.infoText && it.infoText !== ''}}
		<div class="sc-finance-layer-buttons-infotext audi-copy-m">{{=it.infoText}}</div>
	{{?}}
	{{? it.ecom.url && it.ecom.text && it.ecom.text !== ''}}
		<a x-cq-linkchecker="skip" href="{{=it.ecom.url}}" data-pattern="{{=it.ecom.patternUrl}}" class="sc-cta-button audi-button {{? it.ecom.css && it.ecom.css !== ''}} {{= it.ecom.css}}{{?}} sc-finance-layer-buttons-ecom-button" target="_blank" data-button-type="ecom">
			<span class="audi-button__text sc-hyphenate">{{=it.ecom.text}}</span>
		</a>
	{{?}}
	<hr>
	<div class="sc-finance-layer-buttons-dealername audi-headline-order-3">{{=it.ecom.dealerName}}</div>
	<div class="sc-finance-layer-buttons-contact">
		{{? it.url !== '' && it.text !== ''}}
			<a href="{{=it.url}}" x-cq-linkchecker="skip" class="sc-finance-layer-buttons-mail sc-j-finance-layer-buttons-mail nm-layerLink audi-link-m audi-link-m--underline audi-link-m--arrow-front">
				<span class="sc-hyphenate audi-link-m__text">{{=it.text}}</span>
				<svg class="audi-link-m__icon">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/direct-mail-small.svg#nm-icon-direct-mail-small"}}></use>
				</svg>
			</a>
		{{?}}
		{{? it.phone && it.phone.number && it.phone.number !== '' && it.phone.display && it.phone.display !== ''}}
			<a href="tel:{{=it.phone.number}}" class="sc-finance-layer-buttons-phone audi-link-m audi-link-m--underline audi-link-m--arrow-front">
				<span class="audi-link-m__text">{{=it.phone.display}}{{? it.showHotlineText == true}} {{=window.i18n['sc.hotline.free.label']||'(free of charge)'}}{{?}}</span>
				<svg class="audi-link-m__icon">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/voice-call-small.svg#nm-icon-voice-call-small"}}></use>
				</svg>
			</a>
		{{?}}
	</div>
`;

export {exports as scFinanceEcomLayerButtonsTemplate};
