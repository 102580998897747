import {content as CONTENT} from 'core-application';

class ScFinanceLayer {
	constructor() {
		this._layerRegEx = new RegExp('sc-finance-layer');
	}

	static get defaults() {
		return {
			selectorLayerWrapper: '.sc-finance-form'
		};
	}


	/**
	 * private function generating the layer
	 * @param {string} url - the url of the content
	 * @returns {Promise} - the HTMLString of the layer
	 */
	_generateFinanceLayerContent(url) {
		return new Promise((resolve, reject) => {
			const data = this._getinitialDataForForm(url);
			if (!!data) {
				resolve(`<sc-finance-form-element data-domain-name='${data.domain}' data-vehicle='${JSON.stringify(data.vehicle)}' data-product='${JSON.stringify(data.product)}' data-dealer='${JSON.stringify(data.dealer)}' class='sc-finance-form'></sc-finance-form-element>`);
			}
			else {
				reject('no finance layer data');
			}
		});
	}

	/**
	 * private function getting the initial data
	 * @param {string} url - the layerlink
	 * @returns {Object} the initial date for the form
	 */
	_getinitialDataForForm(url) {
		let data = {};
		if (!!url && url !== '') {
			const urlParams = this._decodeUrlSegments(url, this._layerRegEx);

			if (!urlParams) {
				return data;
			}

			data.domain = urlParams.domain || {};
			data.vehicle = this._getVehicleData(urlParams);
			data.product = this._getProductData(urlParams);
			data.dealer = this._getDealerData(urlParams);
		}

		return data;
	}

	/**
	 * @param {Object} urlParams_ - object of url params
	 * @returns {Object} - data object
	 */
	_getVehicleData(urlParams_) { // eslint-disable-line complexity, max-statements
		let valueKey, data = {options: [], customFields: []};
		for (let key in urlParams_) {
			if (!urlParams_.hasOwnProperty(key)) {
				continue;
			}

			if (key.indexOf('vehicle') !== -1) {
				if (key.indexOf('vehicleOption') !== -1) {
					data.options = this._addVehicleAdditionalData(data.options, key, urlParams_[key]);
				}
				else if (key.indexOf('vehicleCustomField') !== -1) {
					data.customFields = this._addVehicleAdditionalData(data.customFields, key, urlParams_[key]);
				}
				else {
					valueKey = key.slice(key.indexOf('vehicle') + 7, key.length);
					data[valueKey] = urlParams_[key];
				}
			}
		}
		return data;
	}

	/**
	 * @param {Object} data_ - data object
	 * @param {String} key_ - string
	 * @param {String} value_ - string
	 * @returns {Object} - data object
	 */
	_addVehicleAdditionalData(data_, key_, value_) {
		const counter = parseInt(key_.slice(key_.indexOf('[') + 1, key_.indexOf(']')), 10) - 1;
		const valueKey = key_.slice(key_.indexOf(']') + 1, key_.length);

		if (!data_[counter]) {
			data_[counter] = {};
		}

		data_[counter][valueKey] = value_;

		return data_;
	}

	/**
	 * @param {Object} urlParams_ - object of url params
	 * @returns {Object} - data object
	 */
	_getProductData(urlParams_) {
		let data = {};

		if (urlParams_.productId) {
			data['@ID'] = urlParams_.productId;
		}

		if (urlParams_.productLabel) {
			data.Label = urlParams_.productLabel;
		}

		return data;
	}

	/**
	 * @param {Object} urlParams_ - object of url params
	 * @returns {Object} - data object
	 */
	_getDealerData(urlParams_) {
		let data = {};

		if (urlParams_.dealerId) {
			data.ID = urlParams_.dealerId;
		}

		if (urlParams_.dealerCountry) {
			data.Country = urlParams_.dealerCountry;
		}

		return data;
	}

	/**
	 * decode params from url segments
	 * @param {string} url_ - url to be examined
	 * @param {RegExp} matcher_ - regExp to mark the start of the url segments
	 * @return {Object} all found params as key value Object
	 */
	_decodeUrlSegments(url_, matcher_) {
		var matched, encodedParams = null,
			segments,
			paramsArr, pkey, pvalue;
		if (!!url_ && matcher_ && typeof matcher_.exec === 'function' && url_.match(matcher_)) {
			matched = matcher_.exec(url_);
			// get url segments after the matching marker
			segments = url_.split(matched[0]);
			// remove leading and/or trailing slashes
			segments = segments[1].replace(/^\/|\/$/g, '');
			paramsArr = segments.split('/');
			// check if tuples of two exist
			if (paramsArr.length && paramsArr.length % 2 === 0) {
				encodedParams = {};
				while (paramsArr.length) {
					pkey = paramsArr.shift();
					pvalue = paramsArr.shift();
					encodedParams[pkey] = pvalue;
				}
			}
		}
		return encodedParams;
	}

	initialize() {
		CONTENT.registerContentProvider({
			name: 'sc-finance-layer', // optional
			regEx: this._layerRegEx, // gueltiger RegExp
			callbackPromise: this._generateFinanceLayerContent.bind(this)
		});
	}
}

const scFinanceLayer = new ScFinanceLayer();
scFinanceLayer.initialize();

export {scFinanceLayer, ScFinanceLayer};
