const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close modal-layer--close-sticky-mobile">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
				</svg>
			</div>
		</div>	
		<div class="modal-layer__inner-content">
		</div>
	</div>
`;

export {exports as modalLayerFinanceTemplate};
