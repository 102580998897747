import {modalLayerFootnoteEngineTemplate} from 'core-application';

const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close">
			<div class="modal-layer--close-button sc-modal-financing-pricing-button-close audi-j-clicktracking-element">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
				</svg>
			</div>
		</div>
		<div class="modal-layer__inner-content sc-modal-financing-pricing">
			<h2 class="audi-headline-order-2 sc-modal-financing-pricing--headline">{{=window.i18n['sc.details.finance.modal.headline']}}</h2>
			<h3 class="audi-headline-order-3 sc-modal-financing-pricing--subheadline">{{=window.i18n['sc.details.finance.modal.subheadline']}}</h3>
			<div class="sc-modal-financing-pricing--table" data-amount-cars="2">
				{{~it.prices : price}}
					<dl class="sc-modal-financing-pricing--attribute">
						<dt class="sc-modal-financing-pricing--attribute-cell">
							<span class="sc-modal-financing-pricing--attribute-title audi-copy-m">
								{{? price.type === "retail" }}
										{{= window.i18n['sc.details.finance.modal.RETAIL_PRICE'] || "Retail Price" }}
										<a href='#fn_price' class='audi-j-footnote-reference' target='_self'>
											<sup class='audi-footnote-anchor__text'>
											</sup>
										</a>
								{{??}}
									{{=window.i18n['sc.details.finance.modal.' + price.type] || price.type}}
								{{?}}
							</span>
						</dt>
						<dd class="sc-modal-financing-pricing--attribute-value">
							<span class="sc-compare-value sc-compare-value-1 audi-copy-m">{{= price.formatted }}</span>
						</dd>
					</dl>
				{{~}}
			</div>
			{{? window.i18n['sc.details.finance.modal.disclaimer.headline'] }}
			<hr>
				<div class="audi-copy-m sc-modal-financing-pricing--disclaimer-headline">
					{{= window.i18n['sc.details.finance.modal.disclaimer.headline']}}
				</div>
				<div class="audi-copy-m">
					{{= window.i18n['sc.details.finance.modal.disclaimer.text']}}
				</div>
			{{?}}
			${modalLayerFootnoteEngineTemplate}
		</div>
	</div>
`;

export {exports as modalLayerAllInPricingTemplate};
