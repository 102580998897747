import {appEvents} from 'core-application';
import {template} from 'core-utils';
import {financeElementTemplate} from './finance-element-template';
import {financeElementTemplateSpain} from './finance-element-template-spain';
import {financeElementTemplateJapan} from './finance-element-template-japan';
import {financeElementTemplateNorway} from './finance-element-template-norway';
import {vehiclesController} from '../../stockcars-bundle';
import {OBJECT_UTILS, FORMAT_UTILS, scPage} from '../../stockcars-utils-bundle';

const EQUALIZED_PRICES_LAYOUT_SUFFIX = 'opt4';
const SPAIN_MARKET_CONTEXT = 'esuc/es';
const UK_MARKET_CONTEXT = 'ukuc/en';
const JAPAN_MARKET_CONTEXT = 'jpuc/ja';
const NORWAY_MARKET_CONTEXT = 'no/no';

export default class FinanceElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		await this._initializeProperties();
		this._render();
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} void
	 */
	async _initializeProperties() { // eslint-disable-line max-statements
		const _vehicleData = await this._getVehicleData();
		if (_vehicleData) {
			this._data = {};
			this._data.type = _vehicleData.type || '';
			this._data.id = _vehicleData.id;
			const usedCarPrefix = this._data.type === 'U' ? 'uc.' : '';

			// checking leasing car
			const isLeasingCar = _vehicleData.leasingCar;
			if (isLeasingCar) {
				this._data.isLeasingCar = isLeasingCar;
			}

			const useDynamicPriceLabels = SETUPS.get('scopes.use.dynamic.price.labels');
			this._data.retailPrice = this._getPrice('retail', _vehicleData)?.formatted;
			const retailNameText = this._getNameText('retail', _vehicleData);

			// to be used as a suffix for the dynamic footnote
			this._data.retailSuffix = retailNameText && useDynamicPriceLabels ? `--${retailNameText.toLowerCase()}` : null;

			const retailPriceLabelKey = retailNameText && useDynamicPriceLabels ? `price--${retailNameText.toLowerCase()}` : 'retailprice';
			this._data.retailPriceLabel = window.i18n[`sc.${usedCarPrefix}tiles.${retailPriceLabelKey}`] || '';

			this._data.regularPrice = SETUPS.get('scopes.hide.regular.price') ? '' : this._getPrice('regular', _vehicleData)?.formatted;
			const regularNameText = this._getNameText('regular', _vehicleData);

			this._data.allInPriceLabel = window.i18n[`sc.result.allinprice`];
			this._data.allInPrice = this._getPrice('all-in', _vehicleData)?.formatted || window.i18n[`sc.result.allinprice.fallback`];

			this._data.expensesLabel = window.i18n[`sc.result.expenses`];
			this._data.expenses = this._getPrice('expenses', _vehicleData)?.formatted ? `${this._getPrice('expenses', _vehicleData)?.formatted} +` : window.i18n[`sc.result.expenses.fallback`];

			// to be used as a suffix for the dynamic footnote
			this._data.regularSuffix = regularNameText && useDynamicPriceLabels ? `--${regularNameText.toLowerCase()}` : null;

			const regularPriceLabelKey = regularNameText && useDynamicPriceLabels ? `price--${regularNameText.toLowerCase()}` : 'regularprice';
			this._data.regularPriceLabel = window.i18n[`sc.${usedCarPrefix}tiles.${regularPriceLabelKey}`] || '';
			this._data.downpayment = this._getPrice('down_payment', _vehicleData)?.formatted;
			this._data.environmenttax = this._getPrice('environment_tax', _vehicleData)?.formatted;
			this._data.financerate = this._getPrice('finance_rate', _vehicleData)?.formatted;
			this._data.nwsRegularPriceFormat = this._data.regularPrice && scPage.isAvailableSoonVehicle(_vehicleData) && this._getAvailableFromDate(_vehicleData) && _vehicleData.nationWideSelling;

			/* Norway Price Properties */
			this._data.basePrice = this._getPrice('custom_price', _vehicleData)?.formatted;
			this._data.basePriceLabel = window.i18n['sc.price.base.label'];
			this._data.combinedTaxes = this._getPrice('registration_tax', _vehicleData)?.formatted;
			this._data.combinedTaxesLabel = window.i18n['sc.price.combined.tax.label'];

			/* Spanish price properties */
			this._data.netCreditAmountLabelES = window.i18n['sc.tiles.es.netcreditamount'];
			this._data.netCreditAmountES = this._getPrice('netcreditamount', _vehicleData)?.formatted;
			const bonusFinancingValue = this._getPrice('bonusfinancing', _vehicleData)?.formatted;
			this._data.bonusFinancingStringES = window.i18n['sc.tiles.es.bonusfinancing']?.replace('${bonusfinancing}', bonusFinancingValue);
			const rateValue = this._getPrice('rate', _vehicleData)?.formatted;
			this._data.rateStringES = rateValue ? window.i18n['sc.tiles.es.rate']?.replace('${rate}', '<strong>' + rateValue + '</strong>') : null;
			this._data.optionsPriceLabelES = window.i18n['sc.tiles.es.optionsprice'];
			this._data.optionsPriceES = this._getPrice('options', _vehicleData)?.formatted;

			this._data.vatLabel = _vehicleData.vatReclaimable ? window.i18n['sc.vat.reclaimable'] : window.i18n['sc.vat.not-reclaimable'];
			this._data.vat = _vehicleData.vat && _vehicleData.vat.code ? _vehicleData.vat.code : '';
			this._data.hideCalculationDisclaimer = SETUPS.get('stockcar.scs.market.path') === UK_MARKET_CONTEXT && this._data.type === 'U';

			this._data.financing = !SETUPS.get('scopes.hide.finance.in.tiles') &&
				SETUPS.get('stockcar.financing.rate.show') &&
				(!_vehicleData.buyableOnline || !SETUPS.get('scopes.hide.finance.for.ecom'))
				? this._getFinanceData(_vehicleData)
				: {};

			this._data.hidePrices = !!SETUPS.get('scopes.hide.prices');
			this._data.financingLayer = this._hasFinancingLayer(_vehicleData.financing);
			this._data.taxation = _vehicleData.taxation || '';
			this._data.financing.showShortDisclaimer = SETUPS.get('scopes.show.short.disclaimer');
			this._data.financing.showSpecialShortDisclaimer = SETUPS.get('scopes.show.special.short.disclaimer');
			this._data.financing.showNwsDisclaimer = SETUPS.get('scopes.show.nws.disclaimer') && _vehicleData.nationWideSelling;
			this._data.isAvailableSoonVehicle = scPage.isAvailableSoonVehicle(_vehicleData);
			this._data.availableFromDate = this._getAvailableFromDate(_vehicleData);
			this._data.hasDealerFinanceFlag = this._hasDealerFinanceFlag(_vehicleData);
			this._data.hideFinanceInformationInLayer = SETUPS.get('scopes.financing.hide.financetable');
			this._data.interpretDisclaimersTextStyle = SETUPS.get('scopes.financing.disclaimers.text.style');
			this._data.hideFinanceProductDisclaimer = SETUPS.get('scopes.financing.hide.product.disclaimer');

			// VAT disclaimer Spain
			const vatDisclaimer = window.i18n['sc.prices.reclaimablevat'];
			const hasVatDisclaimer = vatDisclaimer !== '' && _vehicleData.vatReclaimable;
			this._data.vatDisclaimer = hasVatDisclaimer ? vatDisclaimer : null;
		}

		const marketTemplate = this.closest('[data-market]')?.dataset?.market;
		this._data.hasEqualizedPriceLayout = marketTemplate?.endsWith(EQUALIZED_PRICES_LAYOUT_SUFFIX);
	}

	_hasDealerFinanceFlag(vehicle) {
		const dealerFinancingDataAvailable = vehicle?.dealerFinancingData?.valid === true;
		const isEcomVehicle = vehicle?.buyableOnline === true;
		return !!dealerFinancingDataAvailable && !!isEcomVehicle && !SETUPS.get('scopes.hide.finance.in.tiles') && SETUPS.get('stockcar.financing.rate.show');
	}

	/**
	 * get vehicle data
	 * @private
	 * @returns {object} vehicleData json
	 */
	async _getVehicleData() {
		const data = Object.assign({}, this.dataset);
		let vehicleData;
		try {
			if (data && data.vehicleId) {
				vehicleData = await vehiclesController.loadVehicleById(data.vehicleId);
			}
		}
		catch (err) {
			console.warn(err);
		}
		return vehicleData;
	}

	/**
	 * get price
	 * @private
	 * @param {string} type - (retail or regular)
	 * @param {object} vehicleData_ vehicle data
	 * @returns {string} price
	 */
	_getPrice(type, vehicleData_ = {}) {
		return vehicleData_?.typedPrices?.find(it => it.type === type);
	}

	_getNameText(type, vehicleData_ = {}) {
		return vehicleData_?.typedPrices?.find(it => it.type === type)?.nameText;
	}

	/**
	 * get finance data
	 * @private
	 * @param {object} vehicleData_ vehicleData
	 * @returns {object} financing
	 */
	_getFinanceData(vehicleData_ = {}) { // eslint-disable-line
		let financing = {};
		// Exceptional Financing Rate Display. Currently used in Japanese Market
		if (OBJECT_UTILS.getNestedObject(vehicleData_, ['financeAmount'])) {
			const financeObject = vehicleData_.financeAmount;
			financing.available = true;
			financing.isExceptional = true;
			financing.formattedValue = FORMAT_UTILS.getPriceFormatted((financeObject.value || ''), (financeObject.unit || ''), '');
		}

		// Other financing
		if (OBJECT_UTILS.getNestedObject(vehicleData_, 'financing.parameters'.split('.')) &&
			OBJECT_UTILS.getNestedObject(vehicleData_, 'financing.product.label'.split('.'))) {

			financing.available = true;
			financing.parameter = [];

			if (vehicleData_.financing.parameters) {
				vehicleData_.financing.parameters.forEach((param) => {
					if (param.id === 'Rate') {
						financing.rate = {};
						financing.rate.value = param.formattedValue || '';
						financing.rate.unit = param.unitSymbol || '';
						financing.rate.unitText = param.unit || '';
						financing.rate.formattedValue = FORMAT_UTILS.getPriceFormatted((param.formattedValue || ''), (param.unitSymbol || ''), (param.unit || ''));
					}
					if (param.formattedValue !== '') {
						let parameter = {};
						const currency = SETUPS.get('nemo.locale.currency');
						const isCurrency = param.unit === currency;
						const unit = param.unitSymbol || param.unit || '';
						parameter.id = param.id;
						parameter.text = isCurrency
							? FORMAT_UTILS.getPriceFormatted((param.formattedValue || ''), (param.unitSymbol || ''), (param.unit || ''))
							: `${param.formattedValue || ''} ${unit}`;
						parameter.value = param.formattedValue || '';
						parameter.label = param.label || '';
						parameter.note = param.note || '';
						financing.parameter.push(parameter);
					}
				});
			}
			financing.productLabel = vehicleData_.financing.product.label;
			if (vehicleData_.financing && vehicleData_.financing.productDisclaimer) {
				financing.productDisclaimer = vehicleData_.financing.productDisclaimer || '';
			}
			if (vehicleData_.financing.globalDisclaimer) {
				financing.globalDisclaimer = vehicleData_.financing.globalDisclaimer || '';
			}

			financing.calculationDisclaimer = this._data.hideCalculationDisclaimer ? '' : this._getCalculationDisclaimer(vehicleData_);
			financing.footnote = '<a href=\'#fn_rate\' class=\'audi-j-footnote-reference\' target=\'_self\'><sup class=\'audi-footnote-anchor__text\'></sup></a>';
			if (financing.calculationDisclaimer && financing.calculationDisclaimer !== '') {
				const footNoteRegex = /{=footnote_rate:mandatory}/;
				financing.calculationDisclaimerShowFootnote = true;

				if (footNoteRegex.test(financing.calculationDisclaimer)) {
					financing.calculationDisclaimerShowFootnote = false;
					financing.calculationDisclaimer = financing.calculationDisclaimer.replace('{=footnote_rate:mandatory}', financing.footnote);
				}
			}
		}

		if (!SETUPS.get('scopes.financing.disclaimers.text.style')) {
			if (vehicleData_.financing && vehicleData_.financing.globalDisclaimer && vehicleData_.financing.globalDisclaimer !== '') {
				financing.globalDisclaimer = financing.globalDisclaimer.replace(/<\/?[^>]+(>|$)/g, "");
			}
			if (financing.calculationDisclaimer && financing.calculationDisclaimer !== '') {
				financing.calculationDisclaimer = financing.calculationDisclaimer.replace(/<\/?[^>]+(>|$)/g, "");
			}
		}
		return financing;
	}

	/**
	 * get calculation disclaimer
	 * @private
	 * @param {object} vehicleData_ vehicleData
	 * @returns {string} disclaimer text
	 */
	_getCalculationDisclaimer(vehicleData_) {
		return vehicleData_?.financing?.calculationDisclaimer ? vehicleData_.financing.calculationDisclaimer : '';
	}

	/**
	 * get available from date
	 * @private
	 * @param {object} vehicleData_ vehicleData
	 * @returns {string} formatted date
	 */
	_getAvailableFromDate(vehicleData_) {
		let date = '';
		if (vehicleData_.availableFrom) {
			const d = new Date(vehicleData_.availableFrom);
			date = d.toLocaleString('de-De').split(',')[0];
		}
		return date;
	}

	/**
	 * has financing layer
	 * @private
	 * @param {object} financeData_ financing data
	 * @returns {boolean} hasLayer
	 */
	_hasFinancingLayer(financeData_ = {}) {
		let hasLayer = false;
		const data = Object.assign({}, this.dataset);
		if (financeData_.parameters && !(data.finance === 'false')) {
			financeData_.parameters.forEach((param) => {
				if (param.id && param.id === 'Rate' && param.value) {
					hasLayer = true;
				}
			});
		}
		return hasLayer;
	}

	/**
	 * render
	 * @private
	 * @returns {void} return nothing
	 */
	_render() {
		if (this._data) {
			let financeTemplate = '';
			switch (SETUPS.get('stockcar.scs.market.path')) {
				case NORWAY_MARKET_CONTEXT:
					financeTemplate = this._data.type === 'N' ? financeElementTemplateNorway : financeElementTemplate;
					break;
				case SPAIN_MARKET_CONTEXT:
					financeTemplate = financeElementTemplateSpain;
					break;
				case JAPAN_MARKET_CONTEXT:
					financeTemplate = financeElementTemplateJapan;
					break;
				default:
					financeTemplate = financeElementTemplate;
					break;
			}

			const financeElementHtmlString = template.render(financeTemplate, this._data);
			this.innerHTML = financeElementHtmlString;
			const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
				element: this,
				detail: {element: this}
			});
			if (this._data.isAvailableSoonVehicle && this._data.availableFromDate && window.i18n['sc.tiles.expected.to.be'] !== '' && window.i18n['sc.tiles.available.from'] !== '') {
				this.classList.add('sc-car-tile-finance-element-show-available-soon');
			}
			document.dispatchEvent(contentRendered);
		}
	}
}

if (window.customElements.get('finance-element') === undefined) {
	window.customElements.define('finance-element', FinanceElement);
}
