const availableSoonTemplate = () => `
{{? it.isAvailableSoonVehicle && it.availableFromDate && window.i18n['sc.tiles.expected.to.be'] !== '' && window.i18n['sc.tiles.available.from'] !== ''}}
	<p class="audi-copy-s audi-copy--bold sc-car-tile-available-soon-label">
		{{=window.i18n['sc.tiles.expected.to.be']}}
		<a href='#fn_available_soon' class='audi-j-footnote-reference' target='_self'>
			<sup class='audi-footnote-anchor__text'></sup>
		</a>
		&nbsp;{{=window.i18n['sc.tiles.available.from']}}:&nbsp;

		{{? SETUPS.get('scopes.hide.concrete.available.soon.date')}}
			{{=window.i18n['sc.available.soon.fallback.text']}}
		{{??}}
			{{= it.availableFromDate}}
		{{?}}
	</p>
	<span class="audi-copy-s audi-copy--bold">
		{{=window.i18n['sc.tiles.expected.to.be']}}
		<a href='#fn_expected_price' class='audi-j-footnote-reference' target='_self'>
			<sup class='audi-footnote-anchor__text'></sup>
		</a>:&nbsp;
	</span>
{{?}}
`;
/* eslint-disable no-useless-escape */
const template = `
{{##def.infoFinancingLayer:
	<div class="sc-car-tile-financing-available-and-financing-layer">
		<span class="sc-car-tile-rate-info-icon">
			<a class="js-sc-info-layer-icon" data-vehicle-id="{{=it.id}}">
				<svg class="nm-icon-system-info-small">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
				</svg>
			</a>
		</span>
	</div>
#}}

{{##def.infoDealerFinancingLayer:
	<a class="audi-link-s js-modal-layer-link" data-layer-type="ModalLayerDealerFinanceElement" data-vehicle-id="{{=it.id}}">
		<svg class="nm-icon-system-info-small">
			<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
		</svg>
	</a>
#}}

{{##def.exceptionalFinancing:
<span class="sc-car-tile-rate">
	 {{=window.i18n['sc.details.finance.rate.text1'] + ' '}}
	 <span class='sc-finance-rate'>
	 	{{=it.financing.formattedValue}}
	 </span>
	 <a href='#fn_rate' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
</span>
#}}

{{##def.rateWithFootnoteAndProductLabel:
<span class="sc-car-tile-rate">
	 {{=window.i18n['sc.details.finance.rate.text1']}}
	 <span class='sc-finance-rate'>{{=it.financing.rate.formattedValue}}
		{{=it.financing.footnote}}
	</span>
	 {{=window.i18n['sc.details.finance.rate.text2']}} {{=it.financing.productLabel}}
</span>
#}}

{{##def.showCalculationDisclaimer:
	{{? it.financing.showSpecialShortDisclaimer &&  it.financing.calculationDisclaimer}}
		<span class="sc-car-tile-special-short-disclaimer sc-hypenate audi-copy-s {{=(it.hasEqualizedPriceLayout ? 'disclaimer--equal' : '')}}">
			{{=it.financing.calculationDisclaimer}}
		</span>
	{{?}}
#}}

{{##def.withFinancingLayer:
<div class="sc-rate-info-layer js-sc-info-layer" data-vehicle-id="{{=it.id}}" style="display: none;">
	<div class="sc-rate-info-layer-content-wrapper">
		<div class="sc-rate-info-layer-content">
			<div class="sc-rate-info-layer-close-button" data-vehicle-id="{{=it.id}}">
				<svg class="nm-icon-cross-large">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cross-large.svg#nm-icon-cross-large"}}></use>
				</svg>
			</div>
			{{? !it.hideFinanceInformationInLayer }}
				<p class="sc-rate-product-label audi-headline-order-2">{{=it.financing.productLabel}}</p>
				{{? !it.hideFinanceProductDisclaimer}}
					<p class="sc-rate-product-description audi-headline-order-3">{{=it.financing.productDisclaimer}}</p>
				{{?}}
				<div class="sc-rate-parameter">
				<div class="sc-rate-parameter-table {{? !SETUPS.get('scopes.financing.hide.calculation.disclaimer')}}lastRowWithoutKey{{?}}">
					{{? it.financing.parameter}}
						{{~it.financing.parameter :parameter :indexParameter}}
							{{? !SETUPS.get('scopes.financing.hide.calculation.disclaimer') || it.financing.calculationDisclaimer}}
								<div class="sc-rate-parameter-table-row">
									<div class="sc-rate-parameter-label sc-hyphenate audi-copy-m">
										{{=parameter.label}}
										{{? parameter.note}}
											&nbsp;{{=parameter.note}}
										{{?}}

									</div>
									<div class="sc-rate-parameter-value sc-hyphenate audi-copy-m">
										{{? it.financing.calculationDisclaimer}}
											{{parameter.value = (parameter.value||'').replace('{=footnote_rate:mandatory}','');}}
										{{?}}
										<! -- REMOVE THIS CONDITION ONCE 'disclaimer.calculation' IS REMOVED FROM PARAMETERS -->
										{{? parameter.id !== 'disclaimer.calculation'}}
											{{=parameter.text}} &nbsp;
										{{?}}
										{{? (parameter.id === 'InterestRateNominal') || (parameter.id === 'InterestRateEff')}}
											{{=window.i18n['sc.details.finance.rate.text3']}}
										{{?}}
									</div>
								</div>
							{{?}}
						{{~}}
					{{?}}
				</div>
			</div>
			{{?}}
			{{? it.financing.calculationDisclaimer }}
				<div class="sc-rate-disclaimer audi-copy-m">
					{{=it.financing.calculationDisclaimer}}
				</div>
				</br>
			{{?}}
			{{? it.financing.globalDisclaimer }}
				<div class="sc-rate-disclaimer audi-copy-m">
					{{=it.financing.globalDisclaimer}}
				</div>
			{{?}}
		</div>
	</div>
</div>
#}}

{{? it.regularPrice && !it.hidePrices && !it.nwsRegularPriceFormat}}
	<div class="sc-car-tile-price-regular-price-show-prices">
		<p class="sc-car-tile-price sc-car-tile-regular-price sc-car-tile-price-numbers audi-copy-s" xmlns="http://www.w3.org/1999/html">
			<span class="audi-copy-m">
				<!-- regularprice = katalogpreis -->
				{{=it.regularPriceLabel}}&nbsp;
			</span>
				<span class="audi-copy-m">{{=it.regularPrice}}</span>
			{{? it.financing.showNwsDisclaimer}}
				<sup> * </sup>
			{{?}}
			<a href='#fn_price' class='audi-j-footnote-reference' target='_self'>
				<sup class='audi-footnote-anchor__text'></sup>
			</a>
			<a href='#fn_regular_price' class='audi-j-footnote-reference' target='_self'>
				<sup class='audi-footnote-anchor__text'></sup>
			</a>
			{{? it.regularSuffix}}
				<a href='#fn_price_regular{{=it.regularSuffix}}' class='audi-j-footnote-reference' target='_self'>
					<sup class='audi-footnote-anchor__text'></sup>
				</a>
			{{?}}
		</p>
	</div>
{{?}}

{{? !it.hidePrices}}
	<div class="sc-car-tile-price-all-in-price">
		<div class="sc-car-tile-price-all-in--text audi-copy-s">
			<span class="sc-car-tile-price-all-in--label audi-copy-m">
				{{=it.allInPriceLabel}}&nbsp;
			</span>
			<p class="sc-car-tile-price-numbers audi-copy-m">{{=it.allInPrice}}</p>
			<popover-trigger class="sc-popover-trigger" role="tooltip">
				<svg class="nm-audi-system-info-small" viewBox="0 0 24 24">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/audi-system-info-small.svg#audi-icon-system-info-small"}}></use>
				</svg>
			</popover-trigger>
			<div class="sc-popover-content">
				{{=window.i18n['sc.result.allinprice.tooltip.info']}}
			</div>
		</div>
	</div>
	<div class="sc-car-tile-price-expenses">
		<div class="sc-car-tile-price-expenses--text audi-copy-s">
			<span class="audi-copy-m">
				{{=it.expensesLabel}}&nbsp;
			</span>
			<p class="sc-car-tile-price-numbers--normal audi-copy-m">{{=it.expenses}}</p>
			<popover-trigger class="sc-popover-trigger" role="tooltip">
				<svg class="nm-audi-system-info-small" viewBox="0 0 24 24">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/audi-system-info-small.svg#audi-icon-system-info-small"}}></use>
				</svg>
			</popover-trigger>
			<div class="sc-popover-content">
				{{=window.i18n['sc.result.expenses.tooltip.info']}}
			</div>
		</div>
	</div>
	{{? it.retailPrice && it.retailPrice !== it.regularPrice}}
		<div class="sc-car-tile-price-retail-price">
			<div class="sc-car-tile-price-retail-price--text audi-copy-s">
				{{? !it.hidePrices}}
					${availableSoonTemplate()}
					<span class="audi-copy-m">
						<!-- retailprice = verkaufspreis -->
						{{=it.retailPriceLabel}}&nbsp;
					</span>
					<p class="sc-car-tile-price-numbers--normal {{=it.isAvailableSoonVehicle ? 'audi-copy-s': 'audi-copy-m'}}">{{=it.retailPrice}}</p>
					{{? it.financing.showNwsDisclaimer}}
						<sup> * </sup>
					{{?}}

					{{? it.nwsRegularPriceFormat}}
						(<span class="audi-copy-s">
							{{=window.i18n['sc.tiles.regularprice.prefix']}}
						</span>&nbsp;
						<span class="{{=it.isAvailableSoonVehicle ? 'audi-copy-s': 'sc-car-tile-price-numbers audi-copy-m'}}">
							{{=it.regularPrice}}
						</span>)
					{{?}}

					<a href='#fn_price' class='audi-j-footnote-reference' target='_self'>
						<sup class='audi-footnote-anchor__text'></sup>
					</a>

					{{? it.retailSuffix}}
						<a href='#fn_price_retail{{=it.retailSuffix}}' class='audi-j-footnote-reference' target='_self'>
							<sup class='audi-footnote-anchor__text'></sup>
						</a>
					{{?}}

					<a href='#fn_retail_price' class='audi-j-footnote-reference' target='_self'>
						<sup class='audi-footnote-anchor__text'></sup>
					</a>
					<span> {{? it.type === 'N'}}{{=window.i18n['sc.finance.additional.text.newcar']}}{{??}}{{=window.i18n['sc.finance.additional.text.usedcar']}}{{?}} </span>
					{{? it.vat}}
						<span> ({{=window.i18n['sc.vat.reclaimable']}})
						</span>
					{{?}}

					{{? it.environmenttax}}
						<span class="audi-copy-s">
							<span>
								{{=window.i18n['sc.prices.environmenttax.prefix']}}&nbsp
							</span>
							<span class="sc-car-tile-price-numbers">
								{{=it.environmenttax}}&nbsp
							</span>
							<span>
								{{=window.i18n['sc.prices.environmenttax.suffix']}}&nbsp
							</span>
						</span>
					{{?}}

					{{? it.financerate && it.downpayment}}
						<span class="audi-copy-s">
							<span>
								{{=window.i18n['sc.prices.financerate.prefix']}}&nbsp
							</span>
							<span class="sc-car-tile-price-numbers">
								{{=it.financerate}}&nbsp
							</span>
							<span>
								{{=window.i18n['sc.prices.financerate.suffix']}}&nbsp
							</span>
							<span>
								{{=window.i18n['sc.prices.downpayment.prefix']}}&nbsp
							</span>
							<span class="sc-car-tile-price-numbers">
								{{=it.downpayment}}&nbsp
							</span>
							<span>
								{{=window.i18n['sc.prices.downpayment.suffix']}}&nbsp
							</span>
						</span>
					{{?}}
				{{?}}
			</div>
		</div>
	{{?}}
{{?}}

{{? !it.retailPrice && it.isLeasingCar}}
	<p class="sc-car-tile-financerate-downpayment-environmenttax">
		{{? it.financerate && it.downpayment}}
			<span class="audi-copy-m sc-car-tile-price-numbers">
				{{=it.financerate}}&nbsp
			</span>
			<span class="audi-copy-s">
				<span>
					{{=window.i18n['sc.prices.financerate.suffix']}}&nbsp
				</span>
				<span>
					{{=window.i18n['sc.prices.downpayment.prefix']}}&nbsp
				</span>
				<span class="sc-car-tile-price-numbers">
					{{=it.downpayment}}&nbsp
				</span>
				<span>
					{{=window.i18n['sc.prices.downpayment.suffix']}}&nbsp
				</span>
			</span>
		{{?}}
		{{? it.environmenttax}}
			<span class="audi-copy-s">
				<span>
					{{=window.i18n['sc.prices.environmenttax.prefix']}}&nbsp
				</span>
				<span class="sc-car-tile-price-numbers">
					{{=it.environmenttax}}&nbsp
				</span>
				<span>
					{{=window.i18n['sc.prices.environmenttax.suffix']}}&nbsp
				</span>
			</span>
		{{?}}
	</p>
{{?}}

<!-- Finance info -->
<div class="sc-car-tile-finance-rate">
<div class="sc-car-tile-finance-rate--text audi-copy-s">
{{? it.hasDealerFinanceFlag && it.hasDealerFinanceFlag === true}}
<dealer-finance-element class="sc-dealer-finance-element sc-car-tile-finance-element" data-vehicle-id="{{=it.id}}"></dealer-finance-element>
{{?? it.financing && it.financing.available && !it.financing.isExceptional}}
{{? it.financing.rate}}
	{{? it.financing.showShortDisclaimer}}
		{{var calculationDisclaimer = it.financing.calculationDisclaimer || '';}}
		<span class="sc-car-tile-rate sc-car-tile-finance-rate--text audi-copy-s">
			{{=window.i18n['sc.details.finance.disclaimer.text']}}
			{{=calculationDisclaimer}}
			{{? it.financing.calculationDisclaimerShowFootnote}}
				{{=it.financing.footnote}}
			{{?}}
		</span>
	{{??}}
		<span>
			{{#def.rateWithFootnoteAndProductLabel}}
			{{#def.showCalculationDisclaimer}}
		</span>
	{{?}}
{{??}}
	{{#def.rateWithFootnoteAndProductLabel}}
	{{#def.showCalculationDisclaimer}}
{{?}}

{{? it.financingLayer }}
	{{#def.withFinancingLayer}}
{{?}}
{{?}}

<!-- exceptional financing like currently used in Japan-->
{{? it.financing && it.financing.available && it.financing.isExceptional}}
<p>{{#def.exceptionalFinancing}}</p>
{{?}}

{{? it.taxation && !!it.taxation }}
<p class="sc-car-tile-info sc-info-label-medium"><span class="sc-info-label-medium-bold">{{=window.i18n['sc.result.taxation']||''}}:</span> {{=it.taxation}} <a href='#fn_tax' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a></p>
{{?}}
</div>

	{{? it.hasDealerFinanceFlag && it.hasDealerFinanceFlag === true}}
		{{#def.infoDealerFinancingLayer}}
	{{?? it.financing && it.financing.available && it.financing.rate && it.financingLayer}}
		{{#def.infoFinancingLayer}}
	{{?}}

	{{? !SETUPS.get('scopes.hide.allinpricing')}}
		<financing-allinpricing-element class="sc-financing-allinpricing-info-icon" data-vehicle-id="{{=it.id}}"></financing-allinpricing-element>
	{{?}}
</div>

{{? it.type === 'N' && it.financing.showNwsDisclaimer}}
	<span class="sc-car-tile-nws-disclaimer sc-hypenate audi-copy-s">* {{=window.i18n['sc.finance.nws.info'] || ''}}</span>
{{?}}
`;
export {template as financeElementTemplateJapan};
