
const template = `
<div class="sc-crs-finance-element">
	<span class="sc-detail-rate audi-copy-m">
		<span>{{? it.leasingCar}}{{=window.i18n['sc.fixed.dealer.financing.rate.label']}}{{??}}{{=window.i18n['sc.details.finance.rate.text1']}}{{?}}</span>
		<span class="sc-finance-rate">{{=it.rate}}</span><a href='#fn_rate' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
		<span> {{=window.i18n['sc.details.finance.rate.text2']}}
			{{? it.product}}
				<span class="sc-finance-product">&nbsp;{{=it.product}}</span>
			{{?}}
		</span>
	</span>
	<span class="audi-copy-s sc-detail-carinfo-special-disclaimer sc-j-detail-disclaimer">
		{{? it.duration}}{{=it.duration}}{{?}}
		{{? it.mileage}},&nbsp;{{=it.mileage}}{{?}}
		{{? it.downPaymentAmount}},&nbsp;{{=it.downPaymentAmount}}{{?}}
		{{? it.finalRate && !it.leasingCar}},&nbsp;{{=it.finalRate}}{{?}}
		{{? it.totalCreditAmount}},&nbsp;{{=it.totalCreditAmount}}{{?}}
		{{? it.netCreditAmount}},&nbsp;{{=it.netCreditAmount}}{{?}}
		{{? it.paInterestRate}},&nbsp;{{=it.paInterestRate}}{{?}}
		{{? it.effInterestRate}},&nbsp;{{=it.effInterestRate}}{{?}}
		{{? it.disclaimer.tilesDisclaimer}}.&nbsp;{{=it.disclaimer.tilesDisclaimer}}{{?}}
	</span>
	<a class="audi-link-s audi-link-s--arrow sc-detail-change-rate js-modal-layer-link" data-layer-type="ModalLayerDealerFinanceElement" data-vehicle-id="{{=it.id}}">
		<span class="audi-link-s__text">{{=it.rateChangeText}}</span>
		<svg class="audi-link-s__icon"><use xlink:href="/bin/nemo.static/cms4i-nemo/includes/core-ci/assets/icons/svg/forward-small.svg#audi-icon-forward-small"></use></svg>
	</a>
</div>
`;
export {template as dealerFinanceCarinfoElementTemplate};
