const template = `<div class="sc-finance-form-wrapper">
	<div class="audi-copy-m sc-finance-form-subheadline">
		<span>{{=window.i18n['sc.details.finance.form.dataheadline']||'Bitte geben Sie Ihre Finanzierungsdaten an:'}}</span>
		<div class="sc-finance-response-error"></div>
	</div>

	/* Inner Error-Layer Content */
	<div class="sc-finance-form-response-error">
	</div>

	<form id="sc-finance-form" data-requestdata="{{=it.requestData}}" data-productId="{{=it.productId}}" data-campaignId="{{? it.defaultProduct && it.defaultProduct['@CampaignID']}}{{=it.defaultProduct['@CampaignID']}}{{?}}">
		<div class="sc-finance-form-data">
			{{~it.formData.data :formDataObject}}
				{{? !!formDataObject.label}}
					<label class="audi-copy-m sc-finance-form-section-label">{{= formDataObject.label}}</label>
				{{?}}
				{{~formDataObject.parameter :value:index}}
					{{? !value.control.dataType || value.control.dataType != 'Date'}}
						<div class="sc-finance-form-row">
							{{? value.control.type === 'Hidden'}}
								<input type="hidden" id="{{=value.id}}" value="{{? value.hasData && value.data[0] && value.data[0].text}}{{=value.data[0].text}}{{?}}" class="{{? value.relevant}}sc-finance-form-relevant{{?}}"/>
							{{??}}
								/* SELECT */
								{{? value.control.type === 'Select' }}
									{{? value.control && value.control.style === 'Radio'}}
										<span id="{{=value.id}}" class="{{? value.relevant}}sc-finance-form-relevant{{?}} radio">
											<div class="audi-radiobutton-prelabel audi-copy-s">{{=value.label}}</div>
												{{~value.data :selectvalue:selectindex}}
													{{? !!selectvalue.text}}
														<span class="audi-radiobutton ">
															<input type="radio" id="{{=value.id}}_{{=selectvalue.value}}" name="{{=value.id}}" value="{{=selectvalue.value}}" {{? value.default === selectvalue.value}}checked{{?}} class="audi-radiobutton-input{{? value.control.autoPosBack}} js-sc-finance-form-autopostback{{?}}">
															<label for="{{=value.id}}_{{=selectvalue.value}}" class="audi-radiobutton-label audi-copy-m">{{? selectvalue.text}}{{=selectvalue.text}}{{?}} {{=value.displayUnit}}</label>
														</span>
													{{?}}
												{{~}}
										</span>
									{{??}}
										<div class="audi-dropdown">
											<label class="audi-dropdown-prelabel audi-copy-s" for="{{=value.id}}">{{=value.label}}</label>
											<select class="{{? value.relevant}}sc-finance-form-relevant{{?}} {{? value.control.autoPosBack}}js-sc-finance-form-autopostback{{?}}" id="{{=value.id}}">
												{{~value.data :selectvalue:selectindex}}
													<option value="{{=selectvalue.value}}" {{? value.default === selectvalue.value}}selected{{?}}>
														{{? selectvalue.text}}{{=selectvalue.text}}{{?}} {{=value.displayUnit}}
													</option>
												{{~}}
											</select>
											<svg class="nm-icon-arrow">
												<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
											</svg>
										</div>
									{{?}}
								{{?}}
								/* INPUT */
								{{? value.control.type === 'Edit'}}
									<span class="audi-inputfield">
										<span class="audi-inputfield-prelabel audi-copy-s">{{=value.label}}</span>
										<input type="text" value="{{? value.hasData && value.data[0].text}}{{=value.data[0].text}}{{?}}" id="{{=value.id}}" data-decimal-value="{{? value.hasData}}{{=value.data[0].value}}{{?}}" class="{{? value.control.dataType}}js-sc-finance-form-{{=value.control.dataType}} {{?}}{{? value.relevant}}sc-finance-form-relevant{{?}}" {{? !!value.control.maxInputLength}}maxlength=" {{=value.control.maxInputLength}}"{{?}}/>
										<span class="audi-inputfield-postfix">{{=value.displayUnit}}</span>
									</span>
								{{?}}
								/* LABEL */
								{{? value.control.type === 'Label' && value.label}}
									<span class="audi-inputfield nm-form-readonly">
										<span class="audi-inputfield-prelabel audi-copy-s">
											{{=value.label}}
											{{?value.id === 'Rate'}}
												{{=window.i18n['sc.details.finance.form.rate.label.suffix'] || ''}}
											{{?}}
										</span>
										{{? value.hasData}}
											<input readonly type="text" value="{{? value.data[0].type === 'percentage' && value.data[0].text}}{{=value.data[0].text}} {{=value.displayUnit}}{{??}}{{=value.data[0].formattedValue}}{{?}}"/>
										{{?}}
										{{?value.id === 'Rate'}}
											<span id="sc-finance-form-rate" data-rate="{{? value.data[0].type === 'percentage' && value.data[0].text}}{{=value.data[0].text}} {{=value.displayUnit}}{{??}}{{=value.data[0].formattedValue}}{{?}}" data-product="{{=it.formData.description}}">
											</span>
										{{?}}
									</span>
								{{?}}
								{{? value.hasHelp}}
									<div class="sc-finance-form-help">
										<a class="sc-info-hover-icon js-sc-finance-form-info-icon">
											<svg class="nm-icon-system-info-small">
												<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
											</svg>
										</a>
										<span class="sc-finance-form-help-label audi-copy-s">{{=window.i18n['sc.details.finance.form.help.label']||'Weitere Informationen'}}</span>
										<span class="sc-finance-form-help-popup">
											<span class="sc-ifinance-form-help-popup-rectangle"></span>
											<span class="sc-finance-form-help-popup-inner-content">
												<span class="sc-finance-form-help-popup-close-button">
													<svg class="nm-icon-cross-large">
														<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cross-large.svg#nm-icon-cross-large"}}></use>
													</svg>
												</span>
												<span class="sc-finance-form-help-popup-text audi-copy-s">{{=value.help}}</span>
											</span>
										</span>
									</div>
								{{?}}
							{{?}}
						</div>
					{{?}}
				{{~}}
			{{~}}
		</div>
		<div class="sc-finance-form-button-area">
			<a class="audi-button audi-button--ghost sc-finance-form-button-calculate js-sc-finance-form-calculate"><span class="audi-button__text">{{=window.i18n['sc.details.finance.form.button.calculate']||'neu berechnen'}}</span></a>
			{{?it.isCalculated === true}}
				<a class="audi-button sc-finance-form-button-update js-sc-finance-form-update"><span class="audi-button__text">{{=window.i18n['sc.details.finance.form.button.update']||'aktualisieren'}}</span></a>
			{{?}}
		</div>
	</form>

	{{? !(SETUPS.get('scopes.hide.dynamic.financing.layer.contact.section')) }}
		<sc-finance-layer-buttons class="sc-finance-layer-buttons"></sc-finance-layer-buttons>
	{{?}}

	{{? it.formData.disclaimer}}
		<div class="sc-finance-nonform sc-finance-form-disclaimers">
			<div class="audi-copy-m sc-finance-form-globaldisclaimer">
				{{=window.i18n['sc.details.finance.form.disclaimer']||'Disclaimer'}}
			</div>

			{{? it.formData.disclaimer.productDisclaimer}}
				<div class="audi-copy-m">
					{{=it.formData.disclaimer.productDisclaimer}}
				</div>
			{{?}}
			
			{{? it.formData.disclaimer.globalDisclaimer}}
				<div class="audi-copy-m sc-j-fn-disclaimer-text" data-fn-disclaimer="{{? it.formData.disclaimer.globalDisclaimer}}{{=it.formData.disclaimer.globalDisclaimer}}{{??}}{{=it.formData.disclaimer.groupDisclaimer}}{{?}}">
					{{? it.formData.disclaimer.globalDisclaimer}}{{=it.formData.disclaimer.globalDisclaimer}}{{??}}{{=it.formData.disclaimer.groupDisclaimer}}{{?}}
				</div>
			{{?}}
		</div>
	{{?}}
</div>`;
export {template as scFinanceFormTemplate};
