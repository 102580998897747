import {crsApi as CRS_API} from './crs-api';
import {StorageAdapter} from '@oneaudi/stck-store';
import {STCK_STORE, ACTIONS} from '@oneaudi/stck-store';
import {UrlSetups} from '../../stockcars-bundle';

class ScModelFinanceForm {
	constructor() {
		this._financeStorage = new StorageAdapter(ScModelFinanceForm.defaults.textKeyLocalStorageRate);
	}

	static get defaults() {
		return {
			textKeyLocalStorageRate: 'sc-calculated-rate'
		};
	}

	/**
	 * public function returning the default data
	 * @param {string} domain - the domain of the request
	 * @param {Object} product - the product
	 * @param {Object} vehicle - the vehicle
	 * @param {Object} dealer - the dealer
	 * @returns {Promise} promise for the default data
	 */
	async getDefaultDataForProduct(request) {
		const rawData = await this.getDefaultRawDataForProduct(request);
		const response = this.prepareProductDataResponse(rawData);
		return this._prepareDataForProductResponse(response);
	}

	/**
	 * public function returning the default raw data
	 * @param {string} domain - the domain of the request
	 * @param {Object} product - the product
	 * @param {Object} vehicle - the vehicle
	 * @param {Object} dealer - the dealer
	 * @returns {Promise} promise for the default data
	 */
	async getDefaultRawDataForProduct(request) {
		const url = SETUPS.get('stockcar.financing.crs.serviceurl');
		return await CRS_API.getDefaultRawDataForProduct(url, request);
	}

	prepareDefaultDataForProduct(rawResponse_ = {}) {
		const response = this.prepareProductDataResponse(rawResponse_);
		return this._prepareDataForProductResponse(response);
	}

	/**
	 * private function preparing the product reponse data, stripping it from unnecessaary wrappings and Values
	 * @param {Object} responseData - the products responseData
	 * @returns {Object} return the productdata
	 */
	prepareProductDataResponse(responseData) {
		let returnValue = {};
		if (!!responseData && !!responseData.Response) {
			if (!!responseData.Response.Parameters) {
				returnValue.Parameters = responseData.Response.Parameters;
			}
			if (!!responseData.Response.Result) {
				returnValue.Result = responseData.Response.Result;
			}
			if (!!responseData.Response.Errors) {
				returnValue.Error = responseData.Response.Errors.Error;
			}
		}
		return returnValue;
	}

	/**
	 * public function returning the default data
	 * @returns {Promise} promise for the default data
	 */
	async getDataForProduct(request, vehicleId) { // eslint-disable-line max-params, max-statements
		const url = UrlSetups.financingServiceUrl;
		const rawData = await CRS_API.getDefaultRawDataForProduct(url, request);
		STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
			vehicleId: vehicleId,
			financing: {defaultResponse: rawData}
		}));
		const response = this.prepareProductDataResponse(rawData);
		return this._prepareDataForProductResponse(response);
	}

	/**
	 * public function returning the default data
	 * @param {string} domain - the domain of the request
	 * @param {Object} product - the product
	 * @param {Object} productParameter - the productParameter
	 * @param {Object} vehicle - the vehicle
	 * @param {Object} dealer - dealer data (id and country)
	 * @returns {Promise} promise for the default data
	 */
	async getCalculateRateData(request, defaultProduct, productParameter = null) { // eslint-disable-line max-params, max-statements
		const url = UrlSetups.financingServiceUrl;
		const productParam = (productParameter && productParameter['@ID'] && productParameter['@ID'] !== defaultProduct['@ID']) ? {'Parameter': productParameter} : null;
		const data = await CRS_API.getDefaultRawDataForProduct(url, request, defaultProduct, productParam);
		return data;
	}

	async getCalculateRateDataLeasing(request, defaultProduct, productParameter = null) { // eslint-disable-line max-params, max-statements
		const url = UrlSetups.financingServiceUrl;
		const productParam = (productParameter && productParameter['@ID'] && productParameter['@ID'] !== defaultProduct['@ID']) ? {'Parameter': productParameter} : null;
		const data = await CRS_API.getCalculateRateData(url, request, defaultProduct, productParam);
		return data;
	}

	/**
	 * public function returning the products
	 * @returns {Promise} promise for the productdata
	 */
	async getProducts(request, vehicleId) {
		const url = SETUPS.get('stockcar.financing.crs.serviceurl');
		const data = await CRS_API.getProducts(url, request);
		STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
			vehicleId,
			financing: {products: data.Product}
		}));

		// TODO defaultProduct
		return this._prepareProductResponse(data, {});
	}

	/**
	 * public function persisting the rate data
	 * @param {Object} rate - rate
	 * @param {string} vehicleId - the id of the vehicle
	 * @param {string} vehicleKey - the key of the vehicle
	 * @returns {void}
	 */
	setCaculatedRate(rate, vehicleId, vehicleKey) {
		const storageRateObect = this.getRate();
		storageRateObect[vehicleId] = storageRateObect[vehicleId] || {};
		storageRateObect[vehicleId][vehicleKey] = rate;
		this._setRateToStorage(storageRateObect);
	}

	/**
	 * get the local storage
	 * @method getLocalStorage
	 * @returns {Object} returns localStorage
	 */
	_getLocalStorage() {
		return this._financeStorage;
	}

	/**
	 * set rate object to storage
	 * @param {Object} rate - the rate object
	 * @returns {void}
	 */
	_setRateToStorage(rate) {
		const ls = this._getLocalStorage();
		ls.writeEntry(rate);
	}

	/**
	 * get the rate object from storage
	 * @returns {Object} rate object
	 */
	_getRateFromStorage() {
		const ls = this._getLocalStorage();
		return ls.readEntry() || {};
	}

	getRate() {
		return this._getRateFromStorage();
	}

	/**
	 * private function preparing the product response for the form
	 * @param {Object} data - the raw response
	 * @param {Object} defaultProduct - the defaultProduct
	 * @return {Object} - prepared data
	 */
	_prepareProductResponse(data, defaultProduct) {
		if (!!data && !!data.Product) {
			if (!Array.isArray(data.Product)) {
				const tmpArray = [];
				tmpArray.push(data.Product);
				data.Product = tmpArray;
			}
			data.Product.forEach((product) => {
				if (product['@ID'] === defaultProduct['@ID']) {
					data.defaultProduct = product;
				}
			});
			if (!data.defaultProduct) {
				data.defaultProduct = data.Product[0];
			}
		}
		else {
			throw (data.Error ? data.Error : {});
		}
		return data;
	}

	/**
	 * private function preparing the product data response for the form
	 * @param {Object} data - the raw response
	 * @return {Object} - prepared data
	 */
	_prepareDataForProductResponse(data) {
		if (!!data && !!data.Parameters) {
			// Product description variation
			if (!!data.Parameters.Description && !!data.Parameters.Description['#text']) {
				data.Parameters.Description = data.Parameters.Description['#text'];
			}
			// Group variation
			if (!!data.Parameters.Group && !(data.Parameters.Group instanceof Array)) {
				data.Parameters.Group = [data.Parameters.Group];
			}
		}
		else {
			throw (data.Error ? data.Error : {});
		}
		return data;
	}
}

const scModelFinanceForm = new ScModelFinanceForm();
export {scModelFinanceForm, ScModelFinanceForm};
