const header = () => `
{{? it.isLeasingCar}}
	<div class="sc-finance-element sc-car-tile-finance-element">
		<div class="sc-car-tile-price-retail-price">
			<div class="sc-car-tile-price-retail-price--text audi-copy-s">
{{?}}
`;

const footer = () => `
{{? it.isLeasingCar}}
			</div>
		</div>
	</div>
{{?}}
`;

const infoDealerFinancingLayer = () => `
	{{? it.isLeasingCar}}
		<a class="audi-link-s js-modal-layer-link sc-modal-dealer-financing-link" data-layer-type="ModalLayerDealerFinanceElement" data-vehicle-id="{{=it.id}}">
			<svg class="nm-icon-system-info-small">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
			</svg>
		</a>
	{{?}}
`;

const template = `
	${header()}
	<span class="sc-car-tile-rate">
		 {{? it.rateLabel}}{{=it.rateLabel}}{{?}}
		 <span class='sc-finance-rate'>
			{{=it.rate}}{{=window.i18n['sc.fixed.dealer.financing.currency.unit']}}
		</span>
		&nbsp;{{=window.i18n['sc.details.finance.rate.text2']}}
		{{? it.product}}&nbsp;{{=it.product}}{{?}}
		<div class="sc-dealer-financing-disclaimer-wrapper">
			<span class="sc-car-tile-special-short-disclaimer sc-hypenate audi-copy-s sc-dealer-financing-disclaimer">
				{{? it.productText}}{{=it.productText}}{{?}}
			</span>
			${infoDealerFinancingLayer()}
		</div>
	</span>
	${footer()}
`;
export {template as dealerFinanceElementTemplate};
