import {appEvents} from 'core-application';
import {template} from 'core-utils';
import {crsApi as CRS_API} from './crs-api';
import {ACTIONS, SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {FORMAT_UTILS, OBJECT_UTILS} from '../../stockcars-utils-bundle';
import {crsFinanceElementTemplate} from './templates/crs-finance-element-template';
import {mergeDeep} from './mergeDeep';
import {scModelFinanceForm as FF_MODEL} from './sc-model-finance-form';
import {RequestController, svdModel} from "../../stockcars-bundle";
import {getCalculationDisclaimer} from "./utils";

export default class CrsFinanceElement extends HTMLElement {
	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		if (SETUPS.get('stockcar.financing.rate.show')) {
			const vehicleId = this.dataset.vehicleId || '';
			const vehicleData = await this.loadVehicle(vehicleId);
			this._useSpainTemplate = SETUPS.get('stockcar.scs.market.path')?.split('/').map(part => part.toLowerCase()).includes('es') && vehicleData?.basic.type === 'U';
			const campaign = await this._checkForValidCampaign(vehicleData);
			this._getFinanceData(vehicleData, vehicleId, campaign);
		}
	}

	async loadVehicle(vehicleId) {
		const svd = await svdModel.getSvdVersion();
		const response = await RequestController.fetchVehicleById(vehicleId, svd);
		return response.vehicle || {};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	static disconnectedCallback() {
		document.removeEventListener('FOOTNOTES_RENDERED', this._patchRateFootnote);
	}

	async _getAllActiveCampaignsFromScs() {
		const svd = await svdModel.getSvdVersion();
		const campaigns = await RequestController.fetchCampaigns(svd);
		return campaigns;
	}

	async _checkForValidCampaign(vehicleData_) {
		if (vehicleData_ && vehicleData_.campaigns && vehicleData_.campaigns.length) {
			const activeCampaigns = await this._getAllActiveCampaignsFromScs().catch(() => {
				return [];
			});

			if (!activeCampaigns.length) {
				return this.resetFinancingCampaign(vehicleData_);
			}

			const vehiclesActiveCampaigns = vehicleData_.campaigns.filter(campaign => {
				return campaign.type && campaign.type === 'FINANCING' && campaign.typeDetail && activeCampaigns.some(activeCampaign => activeCampaign.campaignId === campaign.campaignId) && this._isCampaignActive(campaign);
			});

			return vehiclesActiveCampaigns.reduce(this.isOlder, {});
		}
		else {
			return this.resetFinancingCampaign(vehicleData_);
		}
	}

	resetFinancingCampaign(vehicleData_) {
		STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
			vehicleId: vehicleData_.id,
			financing: {campaign: {}}
		}));
		return {};
	}

	isOlder(acc, current) {
		if (!acc.startDate) {
			return current;
		}
		return acc.startDate < current.startDate ? acc : current;
	}

	/**
	 * isCampaignActive
	 * @param {object} campaign_ campaign_
	 * @returns {boolean} is active
	 */
	_isCampaignActive(campaign_) {
		const {startDate, endDate} = campaign_;
		return !!((startDate && endDate) && (Number(startDate) <= Date.now() && Number(endDate) >= Date.now()));
	}

	async _getFinanceData(vehicleData_, vehicleId_, campaign_) {
		try {
			let financeData = vehicleData_.detail.financingRequest.Request;
			if (vehicleData_ && vehicleData_.basic.financing && financeData) {
				if (Object.keys(campaign_).length) {
					financeData = this._setCampaignAsProduct(financeData, campaign_);
				}
				const productFinanceData = vehicleData_.detail.financingProductsRequest?.Request;
				this._getInitialFinanceData(financeData, productFinanceData, vehicleId_, vehicleData_.basic);
			}
		}
		catch (e) {
			console.error(e);
		}
	}

	_setCampaignAsProduct(financeData_, campaign_) {
		const productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(STCK_STORE.state, financeData_.Vehicle.Id) || {};
		if (Object.keys(campaign_).length && financeData_.Product && campaign_.campaignId && campaign_.typeDetail && (!Object.keys(productParameter).length || productParameter && productParameter['@CampaignID'])) {
			financeData_.Product = {
				'@CampaignID': campaign_.campaignId,
				'@ID': campaign_.typeDetail
			};
			STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
				vehicleId: financeData_.id,
				financing: {campaign: campaign_}
			}));
		}
		return financeData_;
	}

	async getVehicleData(vehicleId_) {
		try {
			await STCK_STORE.waitForStateFromStorePromise({conditionFn: (state) => !!SELECTORS.VEHICLES.getVehiclesMap(state).get(vehicleId_)});
			return SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(vehicleId_) || {};
		}
		catch (e) {
			console.error(e);
		}
	}

	// eslint-disable-next-line no-unused-vars
	async _getInitialFinanceData(financeData_ = {}, productFinanceData = null, vehicleId = '', vehicleBasic_ = {}) {
		try {
			const url = SETUPS.get('stockcar.financing.crs.serviceurl');
			let productData = {};
			let defaultProductData = financeData_.Product;
			if (productFinanceData) {
				productData = await FF_MODEL.getProducts(productFinanceData, vehicleId);
				defaultProductData = (productData.defaultProduct && productData.defaultProduct['@ID'] && productData.Product.length > 1) ? {'@ID': productData.defaultProduct['@ID']} : {};
				this._patchProductData(productData, financeData_, defaultProductData, vehicleId);
			}
			else {
				this._patchProductData(financeData_.Product, financeData_, defaultProductData, vehicleId);
			}

			defaultProductData = this._patchFormParameter(vehicleId, defaultProductData) || defaultProductData;
			const defaultResponse = await CRS_API.getDefaultRawDataForProduct(url, mergeDeep(financeData_, {Product: defaultProductData}));

			STCK_STORE.dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
				vehicleId: vehicleId,
				financing: {defaultResponse, defaultProductData, vehicleData: {'default': financeData_, product: productFinanceData}}
			}));

			this._render({
				'vehicleData': financeData_,
				'productData': productData,
				'response': defaultResponse.Response,
				'vehicleBasic': vehicleBasic_
			});
		}
		catch (err) {
			console.error(err);
		}
	}

	_patchFormParameter(vehicleId, defaultProductData) {
		const formParameter = SELECTORS.FINANCING.getFormParameterForVehicleId(STCK_STORE.state, vehicleId);
		if (!!formParameter && Object.keys(formParameter).length > 0) {
			return {...defaultProductData, Parameter: formParameter};
		}
		return defaultProductData;
	}

	_patchProductData(productData, financeData_, defaultProductData, vehicleId) {
		if (productData && productData.Product && productData.Product.length) {
			const productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(STCK_STORE.state, vehicleId) || {};
			if (productParameter && Object.keys(productParameter).length) {
				productData.Product.forEach(product => {
					if (product['@ID'] === productParameter['@ID']) {
						if (financeData_.Product) {
							financeData_.Product['@ID'] = productParameter['@ID'];
						}
						defaultProductData['@ID'] = productParameter['@ID'];
					}
				});
			}
		}
	}

	_getRenderData(data_ = {}) {
		let rateValue = '';
		if (OBJECT_UTILS.getNestedObject(data_, 'response.Parameters.Group'.split('.'))) {
			const group = data_.response.Parameters.Group;
			rateValue = Array.isArray(group) ? CrsFinanceElement._getPaymentValue(group[0].Parameter) : CrsFinanceElement._getPaymentValue(group.Parameter);
		}
		let calculationDisclaimer = getCalculationDisclaimer(data_.response) || '';
		if (!SETUPS.get('scopes.financing.disclaimers.text.style')) {
			calculationDisclaimer = calculationDisclaimer.replace(/<\/?[^>]+(>|$)/g, "");
		}
		return {
			templateData: {
				useSpainTemplate: this._useSpainTemplate,
				hasRate: this._hasRate(data_.vehicleBasic),
				hideChangeRateCTA: this._shouldHideCta(data_.vehicleBasic),
				alternativeText: window.i18n['sc.details.finance.rate.alternativeText'],
				disclaimer: {
					hasShortDisclaimer: SETUPS.get('scopes.show.short.disclaimer'),
					hasSpecialShortDisclaimer: SETUPS.get('scopes.show.special.short.disclaimer'),
					text1: window.i18n['sc.details.finance.disclaimer.text'] || 'Ex.:',
					calculation: calculationDisclaimer
				},
				rate: {
					text1: window.i18n['sc.details.finance.rate.text1'] + ' ' || 'or with rate, e.g. monthly ',
					text2: window.i18n['sc.details.finance.rate.text2'] + ' ' || 'with ',
					value: rateValue,
					description: OBJECT_UTILS.getNestedObject(data_, 'response.Parameters'.split('.')) ? CrsFinanceElement._getPaymentDescription(data_.response.Parameters, data_.vehicleData.id) : '',
					changeText: window.i18n['sc.details.finance.rate.button.change'] || 'Change rate'
				},
				jsonData: JSON.stringify(data_.vehicleData) || '{}'
			}
		};
	}

	_hasRate(vehicleBasic) {
		return SETUPS.get('stockcar.financing.rate.show') && !(vehicleBasic.buyableOnline && vehicleBasic.buyableOnline !== '' && vehicleBasic.buyableOnline !== 'false' && !!SETUPS.get('scopes.hide.finance.for.ecom'));
	}

	_shouldHideCta(vehicleBasic) {
		const scopeEnabled = SETUPS.get('scopes.hide.change.rate.cta');
		const isEcom = vehicleBasic.buyableOnline;
		return scopeEnabled && isEcom;
	}

	static _getPaymentValue(payment_ = []) {
		const payment = payment_.filter(object => object['@ID'] === 'Rate');
		let value = '';
		if (payment.length && payment[0].Data && payment[0].Units) {
			const unitSymbol = typeof payment[0].Units === 'string' ? payment[0].Units : payment[0].Units['@Symbol'],
				unitText = typeof payment[0].Units === 'string' ? payment[0].Units : payment[0].Units['#text'];
			value = FORMAT_UTILS.getPriceFormatted(payment[0].Data['#text'], unitSymbol, unitText);
		}
		return value;
	}

	static getVehicleFinanceCampaign(vehicleId_ = '') {
		if (vehicleId_) {
			const campaignId = SELECTORS.FINANCING.getResponseCampaignForVehicleId(STCK_STORE.state, vehicleId_);
			if (campaignId !== '') {
				return SELECTORS.FINANCING.getFinancingCampaignForVehicleId(STCK_STORE.state, vehicleId_);
			}
		}
		return {};
	}

	static _getPaymentDescription(parameters = {}, vehicleId_ = '') {
		const campaignLabel = CrsFinanceElement._getCampaignLabel(vehicleId_);
		if (campaignLabel !== '') {
			return campaignLabel;
		}
		return parameters.Description['#text'];
	}

	/**
	 * private function get param data
	 * @param {object|string} value_ - value (object or string is possible)
	 * @param {string} type_ - specific type of object (object or string is possible)
	 * @returns {string} returns value string
	 */
	static _getValueString(value_ = '', type_ = '') {
		let value = '';
		if (typeof value_ === 'string') {
			value = value_;
		}
		else {
			value = !!value_[type_] ? value_[type_] : value_[Object.keys(value_)[0]];
		}
		return value;
	}

	static _getCampaignLabel(vehicleId_) {
		const campaign = this.getVehicleFinanceCampaign(vehicleId_);
		const productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(STCK_STORE.state, vehicleId_) || {};
		if (campaign && campaign.filterText && (!Object.keys(productParameter).length || productParameter && productParameter['@CampaignID'])) {
			return campaign.filterText;
		}
		return '';
	}

	getPatchedDisclaimer(financeData = {}) {
		if (!!financeData && financeData.productData && financeData.calculatedData) {
			let productDescription = OBJECT_UTILS.getNestedObject(financeData, 'productData.defaultProduct.Description'.split('.')) ? CrsFinanceElement._getValueString(financeData.productData.defaultProduct.Description) : '',
				campaignLabel = CrsFinanceElement._getCampaignLabel(financeData.vehicleData.id),
				productLabel = OBJECT_UTILS.getNestedObject(financeData, 'productData.defaultProduct.Label'.split('.')) ? CrsFinanceElement._getValueString(financeData.productData.defaultProduct.Label, '#text') : '',
				calculationDisclaimer = OBJECT_UTILS.getNestedObject(financeData, 'calculatedData.Disclaimers.CalculationDisclaimer'.split('.')) ? CrsFinanceElement._getValueString(financeData.calculatedData.Disclaimers.CalculationDisclaimer) : '',
				productDisclaimer = OBJECT_UTILS.getNestedObject(financeData, 'calculatedData.Disclaimers.ProductDisclaimer'.split('.')) ? CrsFinanceElement._getValueString(financeData.calculatedData.Disclaimers.ProductDisclaimer) : '',
				globalDisclaimer = OBJECT_UTILS.getNestedObject(financeData, 'calculatedData.Disclaimers.GlobalDisclaimer'.split('.')) ? CrsFinanceElement._getValueString(financeData.calculatedData.Disclaimers.GlobalDisclaimer) : '';
			productLabel = campaignLabel !== '' ? campaignLabel : productLabel;
			return `${((productLabel && productLabel !== '') ? productLabel : productDescription) + ': '}${calculationDisclaimer ? calculationDisclaimer + '<br/><br>' : ''}${productDisclaimer ? productDisclaimer + '<br/><br>' : ''}${globalDisclaimer ? globalDisclaimer + '<br/><br>' : ''}`;
		}
	}

	_patchRateFootnote(financeData = {}) {
		if (!!financeData && financeData.productData && financeData.calculatedData) {
			const disclaimerText = this.getPatchedDisclaimer(financeData);
			if (!!disclaimerText) {
				const rateFootnotes = [...document.querySelectorAll('[id$="fn_rate"] .sc-fn-text')];
				rateFootnotes.forEach((rate) => {
					rate.innerHTML = disclaimerText;
				});
			}
		}
	}

	_render(data_ = {}) {
		const data = Object.assign({}, data_, this._getRenderData(data_));
		this.innerHTML = template.render(crsFinanceElementTemplate, data);
		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			detail: {
				rateData: data_,
				element: this
			}
		});

		document.dispatchEvent(contentRendered);

		if (!SETUPS.get('scopes.nemo.ONEFOOTER_INCLUDE')) {
			document.addEventListener('FOOTNOTES_RENDERED', () => this._patchRateFootnote(data_));
		}
		else {
			const disclaimer = this.getPatchedDisclaimer(data_);
			if (!!disclaimer) {
				const patchFootnotes = new CustomEvent('patch:footnotes', {
					detail: {
						footnotes: [{Key: 'fn_rate', Text: disclaimer}]
					}
				});
				document.dispatchEvent(patchFootnotes);
			}
		}
	}
}

if (window.customElements.get('crs-finance-element') === undefined) {
	window.customElements.define('crs-finance-element', CrsFinanceElement);
}
