const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close modal-layer--close-sticky-mobile">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
				</svg>
			</div>
		</div>
		<div class="modal-layer__inner-content sc-modal-dealer-financing">
			{{? it.headline !== ''}}
				<h2 class="audi-headline-order-2 sc-modal-dealer-financing--headline">{{=it.headline}}</h2>
			{{?}}
			{{? it.product !== ''}}
				<h3 class="audi-headline-order-3 sc-modal-dealer-financing--product">{{=it.product}}</h3>
			{{?}}
			{{? it.intro !== ''}}
				<p class="audi-copy-m sc-modal-dealer-financing--subheadline">{{=it.intro}}</p>
			{{?}}
			<div class="sc-modal-dealer-financing--table" data-amount-cars="2">
				{{~it.parameters : parameter}}
					{{? (parameter.label || (parameter.id === "Rate" && it.rateLabel)) && parameter.formattedValue}}
						<dl class="sc-modal-dealer-financing--attribute">
							<dt class="sc-modal-dealer-financing--attribute-cell">
								<span class="sc-modal-dealer-financing--attribute-title audi-copy-m">
									{{? parameter.id === 'Rate'}}
										{{=it.rateLabel}}
									{{??}}
										{{=parameter.label}}
									{{?}}
								</span>
							</dt>
							<dd class="sc-modal-dealer-financing--attribute-value">
								<span class="sc-compare-value sc-compare-value-1 audi-copy-m">{{=parameter.formattedValue }}
									{{? parameter.formattedValue}}
										&nbsp;{{=parameter.unit}}
									{{??}}
										%
									{{?}}
								</span>
							</dd>
						</dl>
					{{?}}
				{{~}}
			</div>
			<hr>
			{{~it.disclaimer : item}}
				<div class="audi-copy-m sc-modal-dealer-financing-disclaimer">
					{{=item.disclaimer}}
				</div>
			{{~}}
		</div>
	</div>
`;

export {exports as modalLayerDealerFinanceTemplate};
