const exports = `
	{{? it.headline && it.headline !== ''}}
		<div class="sc-finance-layer-buttons-headline audi-headline-order-3">{{=it.headline}}</div>
	{{?}}
	{{? it.infoText && it.infoText !== ''}}
		<div class="sc-finance-layer-buttons-infotext audi-copy-m">{{=it.infoText}}</div>
	{{?}}
	<div class="sc-finance-layer-buttons-contact">
		{{? it.url && it.text}}
			{{? it.method === 'POST'}}
				<button class="sc-finance-layer-buttons-mail sc-j-finance-layer-buttons-mail audi-link-m audi-link-m--underline audi-link-m--arrow-front js-modal-layer-link{{? it.css && it.css !== ''}} {{= it.css}}{{?}}" data-target-type="{{=it.target}}" data-layer-type="IframePostModalLayerElement" data-button-type="{{=it.type}}" data-url="{{=it.url}}" data-pattern-url="{{=it.patternUrl}}">
					<span class="audi-link-m__text">{{=it.text}}</span>
				</button>
			{{??}}
				<a x-cq-linkchecker="skip" href="{{=it.url}}" data-pattern="{{=it.patternUrl}}" class="sc-finance-layer-buttons-mail sc-j-finance-layer-buttons-mail audi-link-m audi-link-m--underline audi-link-m--arrow-front sc-j-layerLink {{? it.layer}} nm-layerLink{{?}}{{? it.css && it.css !== ''}} {{= it.css}}{{?}}" target="{{=it.target}}" data-button-type="{{=it.type}}">
					<span class="audi-link-m__text sc-hyphenate">{{=it.text}}</span>
				</a>
			{{?}}
		{{?}}
		{{? it.phone && it.phone.number && it.phone.number !== '' && it.phone.display && it.phone.display !== ''}}
			<a href="tel:{{=it.phone.number}}" class="sc-finance-layer-buttons-phone audi-link-m audi-link-m--underline audi-link-m--arrow-front">
				<span class="audi-link-m__text">{{=it.phone.display}}{{? it.showHotlineText == true}} {{=window.i18n['sc.hotline.free.label']||'(free of charge)'}}{{?}}</span>
				<svg class="audi-link-m__icon">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/voice-call-small.svg#nm-icon-voice-call-small"}}></use>
				</svg>
			</a>
		{{?}}
	</div>
`;

export {exports as scFinanceContactLayerButtonsTemplate};
