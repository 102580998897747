import {modalLayer, ModalLayerContentBase} from 'core-application';
import {template} from 'core-utils';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {modalLayerFinanceTemplate} from './modal-layer-finance-template';
import ScControllerFinanceFormElement from '../crs-finance/sc-controller-finance-form-element';

export default class ModalLayerCrsFinanceElement extends ModalLayerContentBase {

	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._bindContextToFunctions();
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
		this._getVehicleIdFromUrl = this._getVehicleIdFromUrl.bind(this);
		this._getFinanceData = this._getFinanceData.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{selectorLayerWrapper: string}>} defaults
	 */
	static get defaults() {
		return {
			selectorLayerWrapper: '.sc-finance-form',
			closeButton: '.modal-layer--close-button'
		};
	}

	static get type() {
		return 'ModalLayerCrsFinanceElement';
	}

	/**
	 * private function generating the layer
	 * @param {object} data - the dataset from the clicked element
	 * @returns {HTMLElement} - the ModalLayerCrsFinanceElement layer
	 */
	static async getContent(data) {
		return new ModalLayerCrsFinanceElement(data);
	}

	/**
	 * private function get vehicle id from url
	 * @param {string} url url
	 * @returns {string} returns only the last id (needed for detail page in layer on top of detail page)
	 */
	_getVehicleIdFromUrl(url = '') {
		const regex = /(sc_detail.*?\.)(([^.\s])+)/gi;
		let vehicleIds = [];
		let matchesArr;

		while ((matchesArr = regex.exec(url))) {
			vehicleIds.push(matchesArr[2]);
		}

		return vehicleIds[vehicleIds.length - 1] || '';
	}

	/**
	 * function get finance data
	 * @returns {object} returns finance data object
	 */
	_getFinanceData() {
		const vehicleId = this._getVehicleIdFromUrl(location.href);
		const requestData = SELECTORS.FINANCING.getDefaultRequestDataForVehicleId(STCK_STORE.state, vehicleId);
		return Object.keys(requestData).length > 0 ? requestData : {};
	}

	/**
	 * function render
	 * @returns {void} returns nothing
	 */
	_render() {
		const financeData = this._getFinanceData();
		this.innerHTML = template.render(modalLayerFinanceTemplate);
		// Safari Bugfix: "A newly constructed custom element must not have attributes value" !!!
		const financeFormCE = new ScControllerFinanceFormElement(financeData.default, financeData.product, this._getVehicleIdFromUrl(location.href));
		this.querySelector('.modal-layer__inner-content').appendChild(financeFormCE);
		// <sc-finance-form-element class='sc-finance-form' data-domain-name='{{=it.domain}}' data-vehicle='{{=it.vehicleJSONString}}' data-product='{{=it.productJSONString}}' data-dealer='{{=it.dealerJSONString}}'>
		this.closeButton = this.querySelector(ModalLayerCrsFinanceElement.defaults.closeButton);
	}

	/**
	 * function close layer
	 * @returns {void} returns nothing
	 */
	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(ModalLayerCrsFinanceElement);

if (window.customElements.get('modal-layer-finance-element') === undefined) {
	window.customElements.define('modal-layer-finance-element', ModalLayerCrsFinanceElement);
}
