/**
 * Library for accessing the CRS-API (Version 2.16)
 */

/*
 * The DOM item module
 * @exports utils/dom
 */
// object for private Methods
const __ = {},
	// public API
	exports = {
		__: __
	},
	_requestNames = {
		calculateRate: 'CalculateRate',
		defaults: 'Defaults',
		products: 'Products'
	},
	_errorResponseMessage = 'Some Error occured during the Request:';

/**
 * private function sending a CRS request
 * @param {string} url - the requestUrl
 * @param {Object} requestData - the request body
 * @return {Promise} a Promise for the data
 */
__.crsRequest = async function (url, requestData) {
	try {
		const response = await fetch(url, {
			body: JSON.stringify(requestData),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'POST'
		});
		const json = await response.json();
		if (__.isErrorResponse(json)) {
			console.warn('crs-api: ' + json.Response.Error.Description);
			return `${_errorResponseMessage} ${json.Response.Error.Description}`;
		}
		else {
			return json;
		}
	}
	catch (error) {
		throw new Error(`${_errorResponseMessage} ${error}`);
	}
};

/**
 * private function preparing the data
 * @param {string} name - the requestname
 * @param {string} domain - the domain of the request
 * @param {Object} product - the product of the request
 * @param {Object} vehicle - the vehicle of the request
 * @param {Object} dealer - the dealer of the request
 * @returns {Object} the prepared data
 */
// eslint-disable-next-line max-params
__.prepareRequestData = function (name, domain, product, vehicle, dealer) {
	// eslint-disable-line max-params, max-statements
	let data = {Request: {}};
	if (!!name && !!domain && !!vehicle) {
		data.Request['@Name'] = name;
		data.Request['@Domain'] = domain;
		data.Request.Vehicle = vehicle;

		if (
			dealer &&
			typeof dealer === 'object' &&
			Object.keys(dealer).length > 0
		) {
			data.Request.Dealer = dealer;
		}

		if (
			!!product &&
			typeof product === 'object' &&
			Object.keys(product).length > 0
		) {
			data.Request.Product = product;
		}
	}
	return data;
};

/**
 * private function preparing the products reponse data, stripping it from unnecessaary wrappings
 * @param {Object} responseData - the products responseData
 * @returns {Object} the productdata
 */
__.prepareProductsResponse = function (responseData) {
	if (
		!!responseData &&
		!!responseData.Response &&
		!!responseData.Response.Products &&
		!!responseData.Response.Products.Product
	) {
		return responseData.Response.Products;
	}
	return {};
};

/**
 * private function preparing the product reponse data, stripping it from unnecessaary wrappings and Values
 * @param {Object} responseData - the products responseData
 * @returns {Object} return the productdata
 */
__.prepareProductDataResponse = function (responseData) {
	let returnValue = {};
	if (
		!!responseData &&
		!!responseData.Response &&
		!!responseData.Response.Parameters
	) {
		returnValue.Parameters = responseData.Response.Parameters;
		if (responseData.Response.Error) {
			returnValue.Error = responseData.Response.Error;
		}
	}
	return returnValue;
};

/**
 * private function preparing the product request data
 * @param {Object} product - the product of the request
 * @param {Object} productParameter - the changed parameter
 * @returns {Object} the prepared data
 */
__.prepareProductRequestData = function (product, productParameter) {
	let returnValue = product ? product : {};
	if (!!product && !!productParameter && !!productParameter.Parameter) {
		returnValue.Parameter = productParameter.Parameter;
	}
	return returnValue;
};

/**
 * private function determining whether the data is an Error or not
 * @param {Object} responseData - the response data
 * @returns {boolean} whether the data is an Error or not
 */
__.isErrorResponse = function (responseData) {
	if (
		!responseData ||
		!responseData.Response ||
		(!!responseData.Response.Error &&
			responseData.Response.Error['@ErrorType'] !== 'User')
	) {
		return true;
	}
	return false;
};

/**
 * public function returning the products for a vehicle
 * @param {string} url -  the url for the request
 * @param {string} domain - the domain for the request e.g. VTP.AUDI.IE
 * @param {Object} vehicle - the vehicle for the request e.g.
 * {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
 * @returns {Promise} returns a Promise for the products
 */
exports.getProducts = function (url, request) {
	const requestData = {Request: {...request, ...{'@Name': _requestNames.products}}};
	if (requestData.Request.Product) {
		delete requestData.Request.Product;
	}
	return __.crsRequest(url, requestData).then(function (data) {
		return __.prepareProductsResponse(data);
	});
};

/**
 * public function returning the default data for a product
 * @param {string} url -  the url for the request
 * @param {string} domain - the domain for the request e.g. VTP.AUDI.IE
 * @param {Object} product - the product for the request e.g. {"@ID": "AC"}
 * @param {Object} vehicle - the vehicle for the request e.g.
 * {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
 * @param {Object} dealer - the dealer for the request e.g. {"ID": "BQ12AA"}
 * @returns {Promise} returns a Promise for the productdata
 */
// eslint-disable-next-line max-params
exports.getDefaultDataForProduct = function (
	url,
	domain,
	product,
	vehicle,
	dealer,
) {
	// eslint-disable-line max-params, max-statements
	const requestData = __.prepareRequestData(
		_requestNames.defaults,
		domain,
		product,
		vehicle,
		dealer,
	);
	return __.crsRequest(url, requestData).then(function (data) {
		return __.prepareProductDataResponse(data);
	});
};

/**
 * public function returning the default raw data for a product
 * @param {string} url -  the url for the request
 * @param {string} domain - the domain for the request e.g. VTP.AUDI.IE
 * @param {Object} product - the product for the request e.g. {"@ID": "AC"}
 * @param {Object} vehicle - the vehicle for the request e.g.
 * {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
 * @param {Object} dealer - the dealer for the request e.g. {"ID": "BQ12AA"}
 * @returns {Promise} returns a Promise for the raw product data
 */
exports.getDefaultRawDataForProduct = function (
	url,
	request,
) {
	const requestData = {Request: {...request, ...{'@Name': _requestNames.defaults}}};
	return __.crsRequest(url, requestData).then(function (data) {
		return data;
	});
};

/**
 * public function returning the data for a product
 * @param {string} url -  the url for the request
 * @param {string} domain - the domain for the request e.g. VTP.AUDI.IE
 * @param {Object} product - the product for the request e.g. {"@ID": "AC"}
 * @param {Object} productParameter - the productParameter for the request e.g. "Parameter": [{"@ID": "Duration","#text": "48"}]
 * @param {Object} vehicle - the vehicle for the request e.g.
 * {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
 * @param {Object} dealer - the dealer for the request e.g. {"ID": "BQ12AA"}
 * @returns {Promise} returns a Promise for the productdata
 */
// eslint-disable-next-line max-params
exports.getDataForProduct = function (
	url,
	domain,
	product,
	productParameter,
	vehicle,
	dealer,
) {
	// eslint-disable-line max-params
	const productData = __.prepareProductRequestData(product, productParameter);
	return exports.getDefaultDataForProduct(
		url,
		domain,
		productData,
		vehicle,
		dealer,
	);
};

/**
 * @param {string} url -  the url for the request
 * @param {string} domain - the domain for the request e.g. VTP.AUDI.IE
 * @param {Object} product - the product for the request e.g. {"@ID": "AC"}
 * @param {Object} productParameter - the productParameter for the request e.g. "Parameter": [{"@ID": "Duration","#text": "48"}]
 * @param {Object} vehicle - the vehicle for the request e.g.
 * {"Key": "BQ12AA", "@Type": "New", "PriceModel": "17850.00", "PriceTotal": "17850.00", "Year": "2018"}
 * @param {Object} dealer - the dealer for the request e.g. {"ID": "BQ12AA"}
 * @returns {Promise} returns a Promise for the productdata
 */
// eslint-disable-next-line max-params
exports.getCalculateRateData = function (
	url,
	request,
	defaultProduct,
	productParameter,
) {
	let productData = __.prepareProductRequestData(defaultProduct, productParameter);
	const requestData = {Request: {...request, ...{'@Name': _requestNames.calculateRate}}};
	if (defaultProduct) {
		requestData.Request.Product = productData;
	}
	return __.crsRequest(url, requestData);
};
export {exports as crsApi};
