const template = `<div class="sc-finance-form-products">
	<h1 class="audi-headline-order-2 sc-finance-form-headline sc-hyphenate">{{=window.i18n['sc.details.finance.form.headline']||'Finanzierungsrechner der Audi Bank'}}</h1>
	<form class="sc-finance-form-products-form">
		{{? it.productData.Product && it.productData.Product.length > 1 }}
			<h2 class="audi-headline-order-3 sc-finance-form-subheadline sc-hyphenate">{{=window.i18n['sc.details.finance.form.productheadline']||'Für welche Finanzierungsoption interessieren sie sich?'}}</h2>
			{{~it.productData.Product :value:index}}
				<span class="audi-radiobutton sc-finance-form-product">
					<input type="radio" id="{{=value['@ID']}}" {{?it.stored === value['@ID'] || value.value && value['@Default'] == 'Yes'}}checked="checked"{{?}}name="sc-finance-product-radio" class="audi-radiobutton-input js-sc-finance-form-product" value="{{? value.value}}{{=value.value}}{{??}}{{=value['@ID']}}{{?}}" data-attributes="{{=it.requestData}}" data-description="{{=value.Description}}" data-campaign="{{?value.Campaign}}{{=value.Campaign}}{{??}}{{?}}"/>
					<label for="{{=value['@ID']}}" class="audi-radiobutton-label audi-copy-m">
						{{? value.Label['#text']}}
							{{=value.Label['#text']}}
						{{??}}
							{{=value.Label}}
						{{?}}
					</label>
				</span>
			{{~}}
		{{?}}

		{{? it.productData && it.productData.defaultProduct && it.productData.defaultProduct.Description}}
			<div class="audi-copy-s sc-finance-form-product-description">
				{{? typeof it.productData.defaultProduct.Description !== 'string'}}
					{{=it.productData.defaultProduct.Description["#cdata-section"]}}
				{{??}}
					{{=it.productData.defaultProduct.Description}}
				{{?}}
			</div>
		{{?}}
	</form>
</div>`;
export {template as scFinanceProductFormTemplate};
