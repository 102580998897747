import {BaseButtonLogic, globalEventEmitter} from '../../stockcars-bundle';
import {scFinanceContactLayerButtonsTemplate} from './templates/sc-finance-contact-layer-buttons-template';
import {scFinanceEcomLayerButtonsTemplate} from './templates/sc-finance-ecom-layer-buttons-template';
import {scFinanceLeasingLayerButtonsTemplate} from './templates/sc-finance-leasing-layer-buttons-template';
import {template} from 'core-utils';

export class ScFinanceLayerButtons extends BaseButtonLogic {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		await this._initialize();
		this._initializeProperties();
	}

	async _initializeProperties() {
		this._addEvents();
	}

	static get defaults() {
		return {
			leasingButton: '.sc-j-finance-layer-buttons-button',
			contactButton: '.sc-j-finance-layer-buttons-mail'
		};
	}

	_addEvents() {
		this.leasingButton = document.querySelector(ScFinanceLayerButtons.defaults.leasingButton);
		this.contactButton = document.querySelector(ScFinanceLayerButtons.defaults.contactButton);

		if (!!this.leasingButton) {
			this.leasingButton.addEventListener('click', this._closeModalLayer);
		}
		if (!!this.contactButton) {
			this.contactButton.addEventListener('click', this._closeModalLayer);
		}
	}

	_closeModalLayer() {
		globalEventEmitter.emit('modalLayer:close', {
			'layerAction': 'close'
		});
	}

	_getTemplateData(buttonData_ = []) {
		let data = {
			'headline': window.i18n[`sc.details.finance.form.${!!buttonData_.type ? buttonData_.type : 'default'}.headline`] || '',
			'infoText': window.i18n[`sc.details.finance.form.${!!buttonData_.type ? buttonData_.type : 'default'}.infoText`] || '',
			'contactHeadline': window.i18n[`sc.details.finance.form.${!!buttonData_.type ? buttonData_.type : 'default'}.contactHeadline`] || ''
		};
		return Object.assign(buttonData_, data, {});
	}

	_getTemplate(type_) {
		if (type_ === 'leasing') {
			return scFinanceLeasingLayerButtonsTemplate;
		}
		else if (type_ === 'ecom') {
			return scFinanceEcomLayerButtonsTemplate;
		}
		return scFinanceContactLayerButtonsTemplate;
	}

	_getFinanceLayerButtons() {
		let btnObj = {};

		const showHotlineText = this.data.vehicleData.nationWideSelling && this.data.vehicleData.type === "U" && !!SETUPS.get('scopes.show.hotline');

		if (this.data.hasFinancing && this.data.vehicleData.leasingCar && this.data.ctaSpecialLinks && this.data.ctaSpecialLinks.leasing) {
			btnObj = Object.assign(this.data.ctaSpecialLinks.leasing, {'phone': this.data.phone}, {'type': 'leasing'}, {'showHotlineText': showHotlineText});
		}
		else if (this.data.vehicleData.buyableOnline === true && !SETUPS.get('stockcar.hide.ecom') && this.data.ctaSpecialLinks.ecom) {
			const ecomData = {
				'vehicleId': this.data.vehicleData.id,
				'btnObjStoreUrl': (this.data.vehicleData.vtpDataStoreUrl ? this.data.vehicleData.vtpDataStoreUrl : ''),
				'pdfUrl': this.data.pdfUrl,
				'dealerName': this.data.dealerName,
				'css': 'sc-j-layerLink'
			};
			btnObj = Object.assign(this.data.ctaSpecialLinks.contact, {'phone': this.data.phone}, {'type': 'ecom'}, {'ecom': Object.assign(this.data.ctaSpecialLinks.ecom, ecomData)}, {'showHotlineText': showHotlineText});
		}
		else {
			btnObj = Object.assign(this.data.ctaSpecialLinks.contact, {'phone': this.data.phone}, {'type': 'contact'}, {'showHotlineText': showHotlineText});
		}
		return btnObj;
	}

	render() {
		let buttonData = this._getFinanceLayerButtons();
		let data = this._getTemplateData(buttonData);
		const dotTemplate = this._getTemplate(data.type);
		this.innerHTML = template.render(dotTemplate, data);
	}
}

if (window.customElements.get('sc-finance-layer-buttons') === undefined) {
	window.customElements.define('sc-finance-layer-buttons', ScFinanceLayerButtons);
}
