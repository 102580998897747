import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {template} from 'core-utils';
import {dealerFinanceElementTemplate} from '../dealer-finance/dealer-finance-element-template';
import {dealerFinanceCarinfoElementTemplate} from '../dealer-finance/dealer-finance-carinfo-element-template';

const firstToLowerCase = (val) => {
	return `${val.charAt(0).toLowerCase()}${val.slice(1)}`;
};

export default class DealerFinanceElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		await this._initializeProperties();
		this._render();
	}

	/**
	 * get parameter item
	 * @private
	 * @param {string} dealerFinancingData - dealer financing data
	 * @param {string} item - name of item
	 * @returns {string} return formatted string
	 */
	_getFormattedParameterValues(dealerFinancingData) {
		const result = {};

		// eslint-disable-next-line no-unused-expressions
		dealerFinancingData?.parameters.forEach((param) => {
			const key = firstToLowerCase(param.id);
			result[key] = param.formattedValue;
		});

		return result;
	}

	// eslint-disable-next-line valid-jsdoc
	/**
	 * returns an object with all disclaimer strings
	 // eslint-disable-next-line valid-jsdoc
	 * @param {{}} data
	 * @returns {{}}
	 * @private
	 */
	_getDisclaimers(data) {
		const result = {};

		// eslint-disable-next-line no-unused-expressions
		data?.disclaimer?.disclaimers.forEach((disclaimer) => {
			const key = firstToLowerCase(disclaimer.key);
			result[key] = disclaimer.disclaimer;
		});

		return result;
	}

	_getFootnote(key) {
		return `<a href='#${key}' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>`;
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} string
	 * @param {string} temp - name of item
	 * @param {array} properties - name of properties
	 */
	_getProductText(temp, properties) {
		let result = temp;
		Object.keys(properties).forEach((property) => {
			const placeholder = new RegExp(`{${property}}`, 'gi');
			const replacement = properties[property];
			result = result.replace(placeholder, replacement);
			result = result.replace(/\[(\w+)\]/gmi, this._getFootnote('$1'));
		});
		return result;
	}

	async _initializeProperties() { // eslint-disable-line max-statements
		const _vehicleData = await this._getVehicleData();
		if (_vehicleData?.dealerFinancingData?.valid) {
			const productId = _vehicleData?.dealerFinancingData?.product?.id;
			const productName = _vehicleData?.dealerFinancingData?.product?.label;
			const isLeasingCar = _vehicleData.leasingCar;
			const rateLabel = isLeasingCar ? window.i18n['sc.fixed.dealer.financing.rate.label'] : window.i18n['sc.details.finance.rate.text1'];
			const rate = _vehicleData.dealerFinancingData.parameters.find((param) => param.id === 'Rate')?.formattedValue;

			const formattedValues = this._getFormattedParameterValues(_vehicleData?.dealerFinancingData);
			const disclaimers = this._getDisclaimers(_vehicleData?.dealerFinancingData);

			const productTextTemplate = window.i18n[`sc.fixed.dealer.financing.disclaimer-${productId}`];
			const productText = this._getProductText(productTextTemplate, {productName, ...formattedValues, ...disclaimers});

			this.data = {
				id: _vehicleData.id,
				isLeasingCar,
				productText,
				rateLabel,
				rate
			};
		}
	}

	/**
	 * function get vehicle data
	 * @returns {object} returns finance data object
	 */
	async _getVehicleData() {
		const data = Object.assign({}, this.dataset);
		if (data && data.vehicleId) {
			try {
				await STCK_STORE.waitForStateFromStorePromise({conditionFn: (state) => !!SELECTORS.VEHICLES.getVehiclesMap(state).get(data.vehicleId)});
				return SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(data.vehicleId) || {};
			}
			catch (e) {
				console.error(e);
			}
		}
	}

	/**
	 * render
	 * @private
	 * @returns {void} return nothing
	 */
	_render() {
		if (this.data) {
			const type = this.dataset.type || '';
			const financeTemplate = type === 'carinfo' ? dealerFinanceCarinfoElementTemplate : dealerFinanceElementTemplate;
			const dealerFinanceElementHtmlString = template.render(financeTemplate, this.data);
			this.innerHTML = dealerFinanceElementHtmlString;
		}
	}
}

if (window.customElements.get('dealer-finance-element') === undefined) {
	window.customElements.define('dealer-finance-element', DealerFinanceElement);
}
