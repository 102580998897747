const exports = `
<a class="js-modal-layer-link sc-financing-allinpricing-info-icon-link" data-layer-type="ModalAllInPricingElement"
data-vehicle-id="{{= it.id}}" title="All In Pricing" data-layer-headline="{{=window.i18n['sc.details.finance.modal.headline']}}" x-cq-linkchecker="skip">
	<svg class="nm-icon-system-info-small">
		<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
	</svg>
</a>
`;

export {exports as financingAllInPricingTemplate};

