const template = `
	<div class="sc-finance-form-general-error audi-copy-s">
		<span class="sc-finance-general-error-headline">
			{{=window.i18n['sc.details.finance.form.general.errorheadline']||'An error occurred.'}}
		</span>
		{{? !!it.Description}}
			{{= ': ' + it.Description}}
		{{?}}
	</div>
`;
export {template as scFinanceFormErrorTemplate};
