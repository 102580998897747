import {modalLayer, ModalLayerContentBase} from "core-application";
import {modalLayerAllInPricingTemplate} from './modal-layer-all-in-pricing-template';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {template} from "core-utils";

export default class ModalAllInPricingElement extends ModalLayerContentBase {

	constructor({vehicleId}) {
		super();
		this._bindContextToFunctions();
		this._data = {vehicleId};
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults (){
		return {
			closeButton: '.modal-layer--close-button'
		};
	}

	static get type() {
		return 'ModalAllInPricingElement';
	}

	static async getContent(data_) {
		return new ModalAllInPricingElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._triggerClose = this._triggerClose.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._triggerClose);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._triggerClose);
	}

	_getDetailedPricingInformation() {
		const fullVehicleData = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(this._data.vehicleId);
		if (!fullVehicleData || !fullVehicleData.typedPrices) {
			this._triggerClose();
			throw (new Error('Could not find the requested auto or the detailed pricing'));
		}

		const displayablePatterns = ['retail', 'CUSTOM_PRICE_1', 'CUSTOM_PRICE_2', 'CUSTOM_PRICE_3', 'CUSTOM_PRICE_4', 'CUSTOM_TAXATION', 'CUSTOM_TAXATION_2'];

		const filteredTypedPrices = [...fullVehicleData.typedPrices].filter(price => displayablePatterns.includes(price.type));

		const sortedTypedPrices = [...filteredTypedPrices].sort((a, b) => {
			if (b.type === 'retail') {
				return -1;
			}
			if (a.type === 'retail') {
				return 1;
			}
			if (b.type.match('CUSTOM_PRICE') && a.type.match('CUSTOM_TAXATION')) {
				return -1;
			}
			if (a.type.match('CUSTOM_PRICE') && b.type.match('CUSTOM_TAXATION')) {
				return 1;
			}
			return a.type - b.type;
		});

		const pricingData = {prices: sortedTypedPrices};

		return pricingData;
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render () {
		try {
			this.innerHTML = template.render(modalLayerAllInPricingTemplate, this._getDetailedPricingInformation());
		}
		catch (err) {
			console.warn(err);
			throw (err);
		}
		this.closeButton = this.querySelector(ModalAllInPricingElement.defaults.closeButton);
	}

}

modalLayer.registerLayerType(ModalAllInPricingElement);

if (window.customElements.get('modal-all-in-pricing-element') === undefined) {
	window.customElements.define('modal-all-in-pricing-element', ModalAllInPricingElement);
}
