/* eslint-disable no-useless-escape */
const template = `
{{##def.infoFinancingLayer:
	<div class="sc-car-tile-financing-available-and-financing-layer">
		<span class="sc-car-tile-rate-info-icon">
			<a class="js-sc-info-layer-icon" data-vehicle-id="{{=it.id}}">
				<svg class="nm-icon-system-info-small">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
				</svg>
			</a>
		</span>
	</div>
#}}

{{##def.withFinancingLayer:
<div class="sc-rate-info-layer js-sc-info-layer" data-vehicle-id="{{=it.id}}" style="display: none;">
	<div class="sc-rate-info-layer-content-wrapper">
		<div class="sc-rate-info-layer-content">
			<div class="sc-rate-info-layer-close-button" data-vehicle-id="{{=it.id}}">
				<svg class="nm-icon-cross-large">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cross-large.svg#nm-icon-cross-large"}}></use>
				</svg>
			</div>
			{{? !it.hideFinanceInformationInLayer }}
				<p class="sc-rate-product-label audi-headline-order-2">{{=it.financing.productLabel}}</p>
				<p class="sc-rate-product-description audi-headline-order-3">{{=it.financing.productDisclaimer}}</p>
				<div class="sc-rate-parameter">
				<div class="sc-rate-parameter-table {{? !SETUPS.get('scopes.financing.hide.calculation.disclaimer')}}lastRowWithoutKey{{?}}">
					{{? it.financing.parameter}}
						{{~it.financing.parameter :parameter :indexParameter}}
							{{? !SETUPS.get('scopes.financing.hide.calculation.disclaimer') || it.financing.calculationDisclaimer}}
								<div class="sc-rate-parameter-table-row">
									<div class="sc-rate-parameter-label sc-hyphenate audi-copy-m">
										{{=parameter.label}}
										{{? parameter.note}}
											&nbsp;{{=parameter.note}}
										{{?}}

									</div>
									<div class="sc-rate-parameter-value sc-hyphenate audi-copy-m">
										{{? it.financing.calculationDisclaimer}}
											{{parameter.value = (parameter.value||'').replace('{=footnote_rate:mandatory}','');}}
										{{?}}
										<! -- REMOVE THIS CONDITION ONCE 'disclaimer.calculation' IS REMOVED FROM PARAMETERS -->
										{{? parameter.id !== 'disclaimer.calculation'}}
											{{=parameter.text}} &nbsp;
										{{?}}
										{{? (parameter.id === 'InterestRateNominal') || (parameter.id === 'InterestRateEff')}}
											{{=window.i18n['sc.details.finance.rate.text3']}}
										{{?}}
									</div>
								</div>
							{{?}}
						{{~}}
					{{?}}
				</div>
			</div>
			{{?}}
			{{? it.financing.calculationDisclaimer }}
				<div class="sc-rate-disclaimer audi-copy-m">
					{{=it.financing.calculationDisclaimer}}
				</div>
				</br>
			{{?}}
			{{? it.financing.globalDisclaimer }}
				<div class="sc-rate-disclaimer audi-copy-m">
					{{=it.financing.globalDisclaimer}}
				</div>
			{{?}}
		</div>
	</div>
</div>
#}}

{{? it.retailPrice && !it.hidePrices && it.retailPrice !== it.regularPrice}}
	<div class="sc-car-tile-price-retail-price spacing-s">
		<div class="sc-car-tile-price-retail-price--text audi-copy-s">
			{{? !it.hidePrices}}
				<span class="audi-copy-m">
					<!-- retailprice = verkaufspreis -->
					{{=it.retailPriceLabel}}&nbsp;
				</span>
				<p class="sc-car-tile-price-numbers audi-copy-m">{{=it.retailPrice}}</p>

				<a href='#fn_price' class='audi-j-footnote-reference' target='_self'>
					<sup class='audi-footnote-anchor__text'></sup>
				</a>

				<a href='#fn_retail_price' class='audi-j-footnote-reference' target='_self'>
					<sup class='audi-footnote-anchor__text'></sup>
				</a>

				{{? it.vatDisclaimer}}
					<p class="audi-copy-m">{{= it.vatDisclaimer}}</p>
				{{?}}

			{{?}}
		</div>
	</div>
{{?}}

{{? it.netCreditAmountES}}
	<div class="audi-copy-s spacing-xxs">
		<span class="audi-copy-m">
			{{=it.netCreditAmountLabelES}}&nbsp;
		</span>
		<p class="sc-car-tile-price-numbers audi-copy-m">
			{{=it.netCreditAmountES}}
			<a href='#fn_net_credit_amount' class='audi-j-footnote-reference' target='_self'>
				<sup class='audi-footnote-anchor__text'></sup>
			</a>
		</p>
	</div>
{{?}}

{{? it.rateStringES}}
	<div class="audi-copy-s spacing-s">
		{{=it.rateStringES}} <sup>*</sup>
	</div>
{{?}}

<!-- Finance info -->
<div class="sc-car-tile-finance-rate">
	<div class="audi-copy-s spacing-s color-grey-60">
	{{? it.financing && it.financing.available && !it.financing.isExceptional}}
		<p class="sc-car-tile-finance-calculation-disclaimer" >
			{{=it.financing.calculationDisclaimer}}
		</p>
		{{? it.financingLayer }}
			{{#def.withFinancingLayer}}
		{{?}}
	{{?}}

	</div>


	{{? it.financing && it.financing.available && it.financing.rate && it.financingLayer}}
		{{#def.infoFinancingLayer}}
	{{?}}
</div>

{{? it.regularPrice && !it.hidePrices && !it.nwsRegularPriceFormat}}
	<p class="sc-car-tile-price-regular-price--spain audi-copy-s" xmlns="http://www.w3.org/1999/html">
		{{=it.regularPriceLabel}}&nbsp;{{=it.regularPrice}}

		<a href='#fn_regular_price' class='audi-j-footnote-reference' target='_self'>
			<sup class='audi-footnote-anchor__text'></sup>
		</a>
	</p>
{{?}}
`;
export {template as financeElementTemplateSpain};
