export function getCalculationDisclaimer(response) {
	if (response.Parameters?.CalculationDisclaimer?.['#cdata-section'] && typeof response.Parameters?.CalculationDisclaimer?.['#cdata-section'] === 'string') {
		return response.Parameters?.CalculationDisclaimer?.['#cdata-section'];
	}
	else if (response.Result?.CalculationDisclaimer?.['#cdata-section'] && typeof response.Result?.CalculationDisclaimer?.['#cdata-section'] === 'string') {
		return response.Result?.CalculationDisclaimer?.['#cdata-section'];
	}
	else if (response.Parameters?.Disclaimers?.CalculationDisclaimer && typeof response.Parameters?.Disclaimers?.CalculationDisclaimer === 'string') {
		return response.Parameters?.Disclaimers?.CalculationDisclaimer;
	}
	else if (response.Result?.Disclaimers?.CalculationDisclaimer && typeof response.Result?.Disclaimers?.CalculationDisclaimer === 'string') {
		return response.Result?.Disclaimers?.CalculationDisclaimer;
	}
	return '';
}


export function getDisclaimerFromFormData(disclaimerName, formData = {}) {
	if (formData.Disclaimers && formData.Disclaimers?.[disclaimerName] && typeof formData.Disclaimers?.[disclaimerName] === 'string') {
		return formData.Disclaimers?.[disclaimerName];
	}
	if (formData[disclaimerName] && formData[disclaimerName]['#cdata-section'] && typeof formData[disclaimerName]['#cdata-section'] === 'string') {
		return formData[disclaimerName]['#cdata-section'];
	}
	return '';
}
